.ticket-manager {

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
  }

  &__discounts {
    padding: 1rem 2rem;
  }

  &__heading {

  }

  &__btns {

  }

  &__row {
    width: 100%;
    // Adding styles for the first child in each row
    th:first-child,
    td:first-child {
        width: 3.5rem;
        white-space: nowrap; // Prevents text wrapping
        min-width: fit-content;
    }
  }

  &__icons {
    display: flex;
    & > * {
      margin: 0 1rem;
    }
  }

  &__icon {

    &:hover {
      cursor: pointer;
    }
  }

  &__edit {
    padding: 2rem;
  }
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.carousel, .landing__events__slider .carousel .carousel-inner .carousel-item {
  position: relative;
}

.carousel.pointer-event, .landing__events__slider .carousel .carousel-inner .pointer-event.carousel-item {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon, [data-bs-theme=dark] .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-control-prev-icon, .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark] .carousel-item .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon,
[data-bs-theme=dark] .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-control-next-icon,
.landing__events__slider .carousel .carousel-inner [data-bs-theme=dark] .carousel-item .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon, .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark].carousel-item .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon,
.landing__events__slider .carousel .carousel-inner [data-bs-theme=dark].carousel-item .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark] .carousel-item .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target], .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark].carousel-item .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark] .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-caption, .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark] .carousel-item .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption, .landing__events__slider .carousel .carousel-inner [data-bs-theme=dark].carousel-item .carousel-caption {
  color: #000;
}

/*
0 - 600px: Phone

600px - 900px: Tablet-Portrait

900px - 1200 px: Tablet-Landscape

[1200px - 1800px]: Normal styling

1800px + : Large desktop

Good working order: Base & Typography -> General Layout & Grid -> Page Layout -> Components

*/
:root {
  --color-primary: #0029FF;
  --color-primary-dark: #310014;
  --color-primary-light: #B04671;
  --color-secondary: #558C8C;
  --color-secondary-dark: #1A5C5C;
  --color-secondary-light: #B9D4D4;
  --color-slate: #535558;
  --color-success: #4cd964;
  --color-error: rgb(214,47,26);
  --color-caution:#FFCC00;
  --color-handicap: #003d83;
  --color-grey-dark: #707070;
  --color-grey-light: #C6C6C6;
  --default-font-size: 1.6rem;
  --default-font-family: "Montserrat", sans-serif;
  --ch-color-white: #ffffff;
  --ch-color-offwhite: #dbdfdb;
  --ch-color-black: #1e1c1a;
  --ch-color-orange: #a9753b;
  --ch-color-grey: #3e4642;
  --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
  --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);
  --line: 1px solid var(--grey-light-2);
}

@font-face {
  font-family: "basis-grotesque-regular";
  src: url("./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.eot");
  src: url("./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.woff2") format("woff2"), url("./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.woff") format("woff"), url("./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "basis-grotesque-medium";
  src: url("./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.eot");
  src: url("./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.woff2") format("woff2"), url("./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.woff") format("woff"), url("./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "basis-grotesque-italic";
  src: url("./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.eot");
  src: url("./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.woff2") format("woff2"), url("./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.woff") format("woff"), url("./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "portrait-light";
  src: url("./assets/fonts/Portrait-Light.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "TrapFont";
  src: url("./assets/fonts/Its-not-a-Trap/Trap-Light.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-Regular.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-Black.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-Bold.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-ExtraBold.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-Medium.otf") format("opentype"), url("./assets/fonts/Its-not-a-Trap/Trap-SemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-Light.otf") format("opentype");
  font-weight: 300; /* Light weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-Regular.otf") format("opentype");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-Medium.otf") format("opentype");
  font-weight: 500; /* Medium weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-SemiBold.otf") format("opentype");
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-Bold.otf") format("opentype");
  font-weight: 700; /* Bold weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-ExtraBold.otf") format("opentype");
  font-weight: 800; /* ExtraBold weight */
  font-style: normal;
}
@font-face {
  font-family: "trap";
  src: url("./assets/fonts/trap/Trap-Black.otf") format("opentype");
  font-weight: 900; /* Black weight */
  font-style: normal;
}
@keyframes animation {}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes pop-out {
  0% {
    left: -21rem;
    transform: rotate(16deg);
  }
  100% {
    left: -32rem;
    transform: rotate(-4deg);
    @media (max-width: 37.5em) {
      left: -29rem;
      transform: rotate(-2deg);
    }
  }
}
@keyframes pop-in {
  0% {
    left: -32rem;
    transform: rotate(-4deg);
    @media (max-width: 37.5em) {
      left: -29rem;
      transform: rotate(-2deg);
    }
  }
  100% {
    left: -21rem;
    transform: rotate(16deg);
  }
}
/* Reset CSS for cross-browser compatibility */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /* allows other elements to inherit box-sizing specified in body */
  box-sizing: inherit;
}

html {
  /* this makes it a lot easier to calcute rem. 62.5 is 10/16 so our defualt fontsize is relative to user settings. defines what 1 rem is */
  scroll-behavior: smooth;
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 45%;
  }
}

body {
  /* makes it so that padding and width don't add to total size of element. i.e. height and width will now extend to define other parts of the box model */
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-weight: 400px;
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}

.heading__1 {
  font-size: 5rem;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.heading__1--ko {
  color: white;
}
.heading__2 {
  font-size: 4.5rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.heading__2--ko {
  color: white;
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.heading__3 {
  font-size: 4rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.heading__4 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.heading__4--ko {
  color: white;
}
.heading__5 {
  color: black;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

label {
  font-size: 1.6rem;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}

p {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3;
}

.p__1 {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.p__1--ko {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: white;
}
.p__2 {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3;
}
.p__2--ko {
  color: white;
}

.subtext {
  color: #8a8787;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
}
.subtext--ko {
  color: #d5d5d5;
}
.subtext--red {
  color: red;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.text__main {
  font-size: 1.6rem;
}
.text__main--ko {
  color: white;
  font-size: 1.6rem;
}

.link--visited:visited {
  color: purple;
}
.link--sm {
  color: #0029FF;
  font-size: 1rem;
}
.link--sm:hover {
  cursor: pointer;
  text-decoration: underline;
}

.u-fade-up-out {
  animation: fadeUpOut 0.3s ease-out forwards !important;
}
.u-move-up-far {
  animation: moveUpFar 0.3s ease-out forwards !important;
}
.u-move-up-close {
  animation: moveUpClose 1.5s ease-out forwards !important;
}
.u-move-up {
  transform: translateY(-15rem);
}
.u-hidden {
  display: none;
}
.u-fadeout {
  animation: fadeOut 1s ease-out forwards;
}
.u-align-center {
  text-align: center;
}
.u-mgn-auto {
  margin: auto;
}
.u-mgn-md {
  margin: 4rem !important;
}
.u-mgn-btm-sm {
  margin-bottom: 1rem !important;
}
.u-mgn-btm-md {
  margin-bottom: 4rem !important;
}
.u-mgn-btm-lg {
  margin-bottom: 8rem !important;
}
.u-mgn-top-sm {
  margin-top: 1rem !important;
}
.u-mgn-top-md {
  margin-top: 4rem !important;
}
.u-mgn-top-lg {
  margin-top: 8rem !important;
}
.u-mgn-left-sm {
  margin-left: 1rem !important;
}
.u-mgn-left-md {
  margin-left: 4rem !important;
}
.u-mgn-left-lg {
  margin-left: 8rem !important;
}
.u-mgn-left-xl {
  margin-left: 12rem !important;
}
.u-mgn-right-sm {
  margin-right: 1rem !important;
}
.u-mgn-right-md {
  margin-right: 4rem !important;
}
.u-mgn-right-lg {
  margin-right: 8rem;
}
.u-mgn-none {
  margin: 0 !important;
}
.u-mgn-vert-sm {
  margin: 1rem 0;
}
.u-mgn-vert-md {
  margin: 4rem 0;
}
.u-mgn-vert-lg {
  margin: 8rem 0;
}
.u-mgn-neg-btm {
  margin-bottom: -1rem;
}
.u-pad-sm {
  padding: 1rem;
}
.u-pad-md {
  padding: 4rem;
}
.u-pad-lg {
  padding: 8rem;
}
.u-pad-btm-sm {
  padding-bottom: 2rem !important;
}
.u-pad-btm-md {
  padding-bottom: 4rem !important;
}
.u-pad-btm-lg {
  padding-bottom: 8rem !important;
}
.u-pad-top-sm {
  padding-top: 2rem !important;
}
.u-pad-top-md {
  padding-top: 4rem !important;
}
.u-pad-top-lg {
  padding-top: 8rem !important;
}
.u-pad-left-sm {
  padding-left: 2rem !important;
}
.u-pad-left-md {
  padding-left: 4rem !important;
}
.u-pad-left-lg {
  padding-left: 8rem !important;
}
.u-pad-right-sm {
  padding-right: 2rem !important;
}
.u-pad-right-md {
  padding-right: 4rem !important;
}
.u-pad-right-lg {
  padding-right: 8rem;
}
.u-pad-none {
  padding: 0 !important;
}
.u-pad-vert-sm {
  padding: 1rem 0;
}
.u-pad-vert-md {
  padding: 2rem 0;
}
.u-pad-vert-lg {
  padding: 4rem 0;
}
.u-pad-hor-sm {
  padding: 0 2rem;
}
.u-pad-hor-md {
  padding: 0 4rem;
}
.u-pad-hor-lg {
  padding: 0 8rem;
}
.u-border-primary-sm {
  border: solid #0029FF 2px !important;
}
.u-inactive {
  display: none !important;
  visibility: hidden;
}
.u-visibility-hidden {
  visibility: hidden;
}
.u-glow {
  box-shadow: 1rem 2rem 2rem rgba(255, 215, 0, 0.6);
}
.u-opaque {
  opacity: 0.4;
}
.u-flex {
  display: flex;
}
.u-flex-column {
  flex-direction: column;
}
.u-flex-row {
  flex-direction: row !important;
}
.u-flex-wrap {
  flex-wrap: wrap;
}
.u-flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.u-flex-justify-start {
  justify-content: flex-start;
}
.u-flex-justify-end {
  justify-content: flex-end;
}
.u-flex-justify-end-self {
  justify-self: flex-end;
}
.u-flex-justify-between {
  justify-content: space-between;
}
.u-flex-justify-evenly {
  justify-content: space-evenly;
}
.u-flex-justify-around {
  justify-content: space-around;
}
.u-flex-justify-center {
  justify-content: center;
}
.u-flex-justify-center-self {
  justify-self: center;
}
.u-flex-align-start {
  align-items: flex-start;
}
.u-flex-align-start-self {
  align-self: flex-start;
}
.u-flex-align-end {
  align-items: flex-end;
}
.u-flex-align-end-self {
  align-self: flex-end;
}
.u-flex-align-center {
  align-items: center;
}
.u-flex-self-justify-end {
  justify-self: flex-end;
}
.u-flex-self-align-end {
  align-self: flex-end;
}
.u-flex-grow {
  flex-grow: 1;
}
.u-top-right {
  position: absolute;
  z-index: 100;
  right: 1rem;
  top: 1rem;
}
.u-pos-relative {
  position: relative;
}
.u-high-z {
  z-index: 10000;
}
.u-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.u-white {
  color: white !important;
}
.u-white-back {
  background-color: white !important;
}
.u-black {
  color: black !important;
}
.u-secondary {
  color: #1F29BA !important;
}
.u-secondary-hov:hover {
  color: #1F29BA !important;
  cursor: pointer;
}
.u-border-default {
  border: 1px solid #0029FF;
}
.u-font-bold {
  font-weight: 900 !important;
}
.u-font-semibold {
  font-weight: 600 !important;
}
.u-full-vh {
  height: 100vh;
}
.u-link--simple {
  color: #0029FF;
}
.u-link--simple:hover {
  color: #0029FF !important;
  text-decoration: underline;
}
.u-hover-pointer:hover {
  cursor: pointer;
}
.u-full-width {
  width: 100% !important;
}
.u-layout-page {
  width: 70%;
  margin: auto;
  overflow: auto;
}
.u-layout-page section p {
  margin-bottom: 2rem;
}
.u-align-center {
  text-align: center;
}
.u-strike {
  text-decoration: line-through;
}
.u-link {
  color: #1A5C5C;
}
.u-overflow-auto {
  overflow: auto;
}

.ch__logo--sm {
  width: 10rem;
}
.ch__font--portrait {
  font-family: "portrait-light";
  margin-bottom: -1rem;
  font-weight: 100;
}
.ch-p {
  font-family: "portrait-light";
  font-size: 1.6rem;
  color: #707070;
}
.ch-p1 {
  font-family: "basis-grotesque-regular";
  font-size: 1.4rem;
  color: #707070;
}
.ch-p1--black {
  color: black !important;
}
@media (max-width: 75em) {
  .ch-p1 {
    font-size: 1.6rem;
  }
}
.ch-p2 {
  font-family: "basis-grotesque-regular";
  font-size: 1.2rem;
  color: #707070;
}
.ch-p3 {
  font-family: "basis-grotesque-regular";
  font-size: 1rem;
  color: #707070;
}
.ch-h1 {
  font-family: "portrait-light";
  font-size: 6rem;
}
.ch-h1--jumbo {
  font-size: 8rem;
  text-transform: uppercase;
}
@media (max-width: 1451px) {
  .ch-h1--jumbo {
    font-size: 5.5rem;
  }
}
@media (max-width: 75em) {
  .ch-h1--jumbo {
    font-size: 8rem;
    max-width: 70%;
  }
}
@media (max-width: 56.25em) {
  .ch-h1--jumbo {
    font-size: 5.5rem;
  }
}
.ch-h2 {
  font-family: "portrait-light";
  font-size: 3rem;
}
.ch-h2--light {
  color: #707070;
}
.ch-h3 {
  font-size: 2.2rem;
  font-family: "portrait-light";
}
.ch-h4 {
  font-size: 1.8rem;
  font-family: "basis-grotesque-regular";
  color: #707070;
}
.ch-hr {
  width: 100%;
  height: 0.5rem;
  background-color: black;
  margin: 8rem 0;
}
.ch-btn {
  background-color: black !important;
  font-family: "portrait-light" !important;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
}
.ch-btn--disabled {
  background-color: #EDEDED !important;
  color: black !important;
}
.ch-cart {
  border-bottom: #EDEDED 1px solid;
  width: 20rem;
  overflow-x: auto;
}
.ch-cart__seat {
  margin-bottom: 0 !important;
  margin-right: 2rem !important;
}
.ch__checkout {
  background-color: #EDEDED;
  border-radius: 5rem;
  margin-left: 4rem;
  width: 90%;
}
.ch__poster {
  width: 76rem;
  height: 47rem;
  border-radius: 2rem;
  background-size: cover !important;
}
@media (max-width: 1451px) {
  .ch__poster {
    width: 63rem;
    height: 39rem;
  }
}
@media (max-width: 75em) {
  .ch__poster {
    width: 91rem;
    height: 54rem;
  }
}
@media (max-width: 56.25em) {
  .ch__poster {
    width: 40rem;
    height: 36rem;
  }
}
@media (max-width: 75em) {
  .ch__poster--container {
    margin-right: 0;
  }
}
.ch-footer {
  background-color: black !important;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  padding: 4rem !important;
}
.ch-footer__top {
  width: 100%;
  display: flex;
}
@media (max-width: 56.25em) {
  .ch-footer__right {
    margin-top: 0 !important;
    width: 60%;
  }
  .ch-footer__right > * {
    font-size: 1rem !important;
  }
}
@media (max-width: 56.25em) {
  .ch-footer__left {
    display: none !important;
  }
}
.ch-footer__divider {
  border: 1px solid white;
  width: 100%;
  margin: 4rem 0;
}
.ch-footer__btm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ch-footer__logo {
  width: 15rem;
}
.ch-footer__logo--container {
  border-right: 1px #EDEDED solid;
  display: inline-block;
  padding-right: 4rem;
  margin-right: 4rem;
}
.ch-footer__st-logo {
  width: 10rem;
}
.ch-overview {
  margin-right: 2rem;
}
.ch-overview__text {
  flex: 2;
}
.ch-navbar {
  width: 100%;
  padding: 4rem 1rem;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ch-navbar__logo {
  width: 75%;
}
@media (max-width: 56.25em) {
  .ch-navbar__logo {
    width: 20rem;
  }
}
.ch-navbar__stripe {
  background-color: black;
  height: 2rem;
}
@media (max-width: 56.25em) {
  .ch-navbar__stripe {
    margin-bottom: -4rem;
  }
}
.ch-navbar__section {
  width: 33%;
  display: flex;
}
@media (max-width: 56.25em) {
  .ch-navbar {
    padding: 1rem 1rem;
    max-height: 8rem;
  }
}
@media (max-width: 56.25em) {
  .ch-navbar__right {
    visibility: hidden;
    width: 30%;
  }
}
.ch__hero {
  padding: 0 14rem;
}
@media (max-width: 75em) {
  .ch__hero {
    padding: 0 7rem;
  }
}
@media (max-width: 75em) {
  .ch__hero--container {
    display: flex;
    flex-direction: column-reverse;
  }
}
.ch__hero-text {
  max-width: 80%;
}
@media (max-width: 75em) {
  .ch__hero-text {
    max-width: 50%;
  }
}
@media (max-width: 56.25em) {
  .ch__hero-text {
    max-width: 100%;
  }
}
.ch__hero--right {
  align-self: flex-start;
}
@media (max-width: 75em) {
  .ch__hero--right {
    margin-bottom: 4rem;
  }
}
.ch__hero--btm {
  margin-top: 3rem;
}
.ch__floorplan {
  margin-left: 11rem;
  overflow: auto;
}
@media (max-width: 56.25em) {
  .ch__floorplan {
    margin-left: 1rem;
  }
}
.ch__info {
  display: flex;
}
@media (max-width: 56.25em) {
  .ch__info {
    flex-direction: column;
  }
}

.alert-toast {
  background-color: #0029FF !important;
  color: white !important;
  font-weight: 900 !important;
  font-size: 1.6rem !important;
  padding: 1rem 2rem 2rem 2rem !important;
}
.alert-toast__success {
  background: #4cd964 !important;
}
.alert-toast__error {
  background: rgb(214, 47, 26) !important;
}
.alert-toast__warning {
  background: #1F29BA !important;
}

.Toastify__close-button--light {
  color: white !important;
  opacity: 0.7;
}

.button {
  background-color: #0029FF;
  color: white;
  border: none;
  padding: 1rem 4rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 700;
}
.button:hover {
  cursor: pointer;
}
.button--alt {
  color: white;
  border: none;
  padding: 1rem 4rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 700;
  background: linear-gradient(270deg, #FF978E 105.51%, #FFB784 -51.89%);
}
.button:active {
  opacity: 0.8;
  transition: 0.2s;
}
.button:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.button--sm {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}
.button--ko {
  background-color: transparent;
  color: #0029FF;
  border: 1px solid #0029FF;
  font-weight: 500;
}
.button--disabled {
  background: #EDEDED !important;
  color: black !important;
}
.button--disabled:hover {
  transform: translateY(0px);
  transition: 0.2s;
  cursor: default;
}
.button--action {
  background: var(--slicktix-gradiant-1, linear-gradient(90deg, #9BC9FF 0%, #D59EEF 55%, #FF7FE3 100%));
}
.button--inactive {
  background-color: #535558;
}
.button--inactive:hover {
  cursor: default;
  transform: translateY(0) !important;
}
.button__add {
  font-size: 2rem;
}
.button__tab {
  background-color: #0029FF;
  border: none;
  padding: 1rem;
  border-radius: 30px;
  font-size: 1.6rem;
  font-weight: 700;
  color: white;
}
.button__tab:active {
  transform: translateY(2px);
  transition: 0.2s;
}
.button__tab:hover {
  cursor: pointer;
  transform: translateY(-2px);
  transition: 0.2s;
}
.button__tab--ko {
  background-color: #1F29BA;
  color: white;
}
.button__tab--active {
  background-color: #310014;
}
.button--pad {
  padding: 2rem 4rem;
}
.button--brand--lg {
  padding: 1rem 6rem;
  font-size: 2.4rem;
  font-family: Impact;
}
.button--brand--primary {
  background: linear-gradient(to top right, #9972fb, #cb72fb);
}
.button--brand--secondary {
  background: linear-gradient(to top right, #FEC180, #FF8993);
}

.manage-btn--edit {
  background-color: #CBE9FF;
  color: #0097FF;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.6rem;
}
.manage-btn--edit:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: 0.4s;
}
.manage-btn--delete {
  background-color: rgba(255, 11, 11, 0.7);
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.6rem;
}
.manage-btn--delete:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: 0.4s;
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  z-index: 100;
  height: 100%;
  width: 100%;
}
.card__side {
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: white;
}
.card__side--back {
  transform: rotateY(180deg);
  background-color: #0029FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__side--back-1 {
  background-image: linear-gradient(to right bottom, #0029FF, #1F29BA);
}
.card__side--back-2 {
  background-image: linear-gradient(to right bottom, #0029FF, #1F29BA);
}
.card__side--back-3 {
  background-image: linear-gradient(to right bottom, #0029FF, #1F29BA);
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__picture {
  background-size: cover;
  height: 23rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  text-align: right;
  position: absolute;
  top: 12rem;
  right: 3rem;
  width: 45%;
}
.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 1rem 1.5rem;
}
.card__about {
  padding: 2rem;
}
.card__about--name {
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase;
}
.card__about--desc {
  font-size: 1.4rem !important;
  color: black !important;
}
.card__details {
  padding: 2rem;
  color: white;
}
.card__details--stack {
  font-size: 3rem;
  text-align: center;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  word-wrap: break-word;
}
.card__details--logo {
  height: 5rem;
  width: 5rem;
  transform: translateY(-25%);
  position: absolute;
  left: 0;
}
.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1.5rem;
  position: relative;
  text-transform: uppercase;
}
.card__details ul li:not(:last-child) {
  border-bottom: 1px solid rgb(200, 200, 200);
}
.cart {
  display: flex;
  flex-direction: column;
}
.cart__seats {
  border: solid 3px #0029FF;
  border-radius: 4px;
  height: 35rem;
  overflow-y: auto;
  padding: 1.5rem;
}
.cart__img {
  width: 20rem;
  max-height: 15rem;
}
.cart__ticket {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  color: white;
  background-image: linear-gradient(to top right, #310014, #F4F9FF);
}
.cart__ticket span {
  font-weight: 700;
  font-size: 1.6rem;
}
.cart__ticket:not(:last-child) {
  margin-bottom: 1rem;
}
.cart__legend {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8rem;
}
@media (max-width: 37.5em) {
  .cart__legend {
    margin: 0;
  }
}
.cart__legend--item {
  padding: 1.5rem 0.5rem;
  border-radius: 10px 10px 0 0;
  width: 7rem;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cart__legend--item:not(:last-child) {
  margin-right: 2rem;
}
.cart__remove {
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  color: white;
}
.cart__price {
  background-color: white;
  border-radius: 3rem;
  padding: 1.5rem 3rem;
  margin-top: 4rem;
}

.landing__events__slider .carousel, .landing__events__slider .carousel .carousel-inner .carousel-item {
  width: 100%;
  height: 100%;
  max-height: 63vh;
  overflow: hidden;
}
.landing__events__slider .carousel .carousel-indicators li, .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-indicators li {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.landing__events__slider .carousel .carousel-control-prev, .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-control-prev, .landing__events__slider .carousel .carousel-control-next, .landing__events__slider .carousel .carousel-inner .carousel-item .carousel-control-next {
  display: none;
}

.collapsible {
  width: 100%;
  margin-bottom: 3px;
}
.collapsible__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  border: none;
  font-size: 1.8rem;
}
.collapsible__btn:hover {
  cursor: pointer;
}
.collapsible__btn:hover * {
  font-weight: 700;
}
.collapsible__btn:focus {
  border-radius: 0;
  outline: none;
}
.collapsible__btn--title {
  margin-right: 2rem;
  font-size: inherit;
}
.collapsible__btn--sm {
  font-size: 1.4rem !important;
  color: white;
  padding: 0.5rem;
}
.collapsible__content {
  width: 100%;
}
.collapsible__icon {
  color: white;
  font-size: 2rem;
}
.collapsible__icon--sm {
  color: white;
}

.discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount__btn {
  width: 8rem;
}
.discount__input {
  width: 75%;
  margin-right: 2rem;
}
.discount__input input {
  margin-bottom: 0;
}

.dropdown {
  height: 100%;
}
.dropdown__btn {
  border: none;
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 4rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 700;
  display: inline-block;
}
.dropdown__btn * {
  transition: color 0.2s;
}
.dropdown__btn--active {
  background-color: black;
}
.dropdown__btn:hover {
  cursor: pointer;
  transition: 0.2s;
  background-color: #0029FF;
}
.dropdown__btn:hover * {
  transition: color 0.2s;
}
.dropdown__content {
  position: absolute;
  z-index: 99;
  background-color: white;
  padding: 2rem;
  width: 20rem;
  background-color: #d5d5d5;
  top: 6rem;
}
.dropdown__content--right {
  right: 0;
}
.edit-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-field__input {
  max-width: 80%;
  margin-right: 1rem;
}
.edit-field__text {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
}
.edit-field__text--ko {
  color: white;
}
.edit-field__edit {
  font-size: 1.6rem;
}
.edit-field__edit:hover {
  cursor: pointer;
}

.event-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.event-card:hover {
  transform: scale(1.03);
}
.event-card__img {
  background-color: #E5E5E5;
  width: 100%;
  min-height: 86%;
  height: 86%;
  object-fit: cover;
  border-radius: 25px;
}
.event-card__info {
  width: 100%;
  height: 14%;
  padding-top: 2.5rem;
}
.event-card__info > * {
  margin: 0;
  width: 100%;
  line-height: 1;
  overflow-wrap: break-word;
  overflow: hidden;
}
.event-card__info > h3 {
  margin-bottom: 4px;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
}
@media (max-width: 37.5em) {
  .event-card__info > h3 {
    font-size: 2.8rem;
  }
}
.event-card__info > p {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 1px;
  color: #8a8787;
}
@media (max-width: 37.5em) {
  .event-card__info > p {
    font-size: 2.2rem;
  }
}

.hover {
  position: relative;
  overflow: hidden;
}
.hover__cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 41, 255, 0.5);
  pointer-events: none;
  z-index: 9999;
}
.hover__cursor--hover {
  background-color: rgba(255, 0, 0, 0.5); /* Change color on hover */
}
.hover__shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  pointer-events: none;
}
.hover__shapes__shape {
  position: absolute;
  will-change: transform;
  -webkit-will-change: transform;
  -moz-will-change: transform;
  -ms-will-change: transform;
  -o-will-change: transform;
  border-radius: 50%;
  z-index: 10000;
  opacity: 0;
  overflow: hidden;
}
@media (max-width: 37.5em) {
  .hover__shapes {
    display: none;
  }
}
.hover__content {
  position: relative;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  mix-blend-mode: screen;
  -webkit-mix-blend-mode: screen;
  -moz-mix-blend-mode: screen;
  -ms-mix-blend-mode: screen;
  -o-mix-blend-mode: screen;
  background: white;
  overflow: hidden;
}
.hover__content--mobile {
  position: relative;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.hz-scroll {
  position: relative;
  background-color: #F4F9FF;
}
.hz-scroll__container {
  position: sticky;
  top: 0;
  display: flex;
  height: 100vh;
  overflow: hidden;
  align-items: center;
}
.hz-scroll__motion {
  display: flex;
  gap: 2rem;
}

.input {
  width: 100%;
  border: none;
  border: 1px solid #929292;
  background-color: inherit;
  font-family: inherit;
  margin: 0 0 2rem 0;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  border-radius: 1.2rem;
}
.input__container {
  position: relative;
}
.input__price {
  padding-right: 0.5rem;
  font-size: 1.8rem;
  position: absolute;
  left: 1rem;
  bottom: 2.5rem;
}
.input__label {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  position: relative;
}
.input__required {
  color: red;
  font-size: 1.4rem;
  position: absolute;
  right: -0.8rem;
  top: -0.5rem;
}
.input__select {
  width: 100%;
  border: none;
  border: 1px solid #929292;
  background-color: inherit;
  font-family: inherit;
  margin: 0 0 2rem 0;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  border-radius: 1.2rem;
}
.input__textarea {
  width: 100%;
  height: 100px;
}
.input__checkbox {
  width: 2rem;
  height: 2rem;
  border: 2px solid black;
  border-radius: 4px;
  appearance: none;
  cursor: pointer;
}
.input__checkbox:checked {
  background-color: #0029FF;
  border-color: #0029FF;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.6L5 9.6L13 1.6' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}
.input__checkbox:hover, .input__checkbox:focus {
  border-color: #0029FF;
}
.input__radio {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.input__radio input[type=radio] {
  appearance: none;
  width: 2rem;
  height: 2rem;
  border: 2px solid #0029FF;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.input__radio input[type=radio]:checked {
  border-color: #0029FF;
  background-color: white;
}
.input__radio input[type=radio]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  background-color: #0029FF;
  border-radius: 50%;
}
.input__radio input[type=radio]:hover, .input__radio input[type=radio]:focus {
  border-color: #3354ff;
}

.InputElement {
  font-size: 1.4rem !important;
}

#card-element {
  background-color: #EDEDED !important;
  font-family: "basis-grotesque-regular" !important;
  padding: 1rem 1.5rem !important;
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #707070 !important;
}

.image-upload {
  display: flex;
  flex-direction: column;
}
.image-upload__label {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.image-upload__sublabel {
  font-size: 1.2rem;
  color: rgb(60, 60, 60);
  margin-bottom: 2rem;
}
.image-upload__input {
  display: none;
}
.image-upload__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: #0029FF;
  color: white;
  border-radius: 50%;
  position: absolute;
  transition: top 0.3s;
  top: 50%;
  transform: translateY(-50%);
}
.image-upload__body:hover {
  cursor: pointer;
  transition: 0.2s;
}
.image-upload__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 22rem;
  background-color: #e0e0e0;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  /* Ensures the image does not overflow the parent */
}
.image-upload__container--file .image-upload__body {
  top: 1rem;
  transform: translateY(0);
}
.image-upload__placeholder {
  content: "+";
  font-size: 2rem;
}
.image-upload__feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #000;
  margin-top: 2rem;
  width: 100%;
  /* Ensures the feedback takes the full width */
  text-align: center;
}
.image-upload__preview {
  max-width: 10rem;
  max-height: 8rem;
  margin-top: 0.5rem;
  border-radius: 8px;
}
.image-upload--small .image-upload__body {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.image-upload--small .image-upload__container {
  width: 8rem;
  height: 10rem;
}
.image-upload--small .image-upload__feedback {
  font-size: 0.9rem;
}
.image-upload--small .image-upload__preview {
  max-width: 5rem;
  max-height: 4rem;
}

.date-range * {
  font-family: "Montserrat", sans-serif;
}
.DateInput {
  background-color: transparent;
  width: 13rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .DateInput {
    width: 10rem;
  }
}

.DateInput_input {
  background-color: transparent !important;
}
.DateRangePicker {
  margin-right: 2rem;
}

.DateRangePickerInput {
  display: flex !important;
  align-items: center;
}

@media (max-width: 37.5em) {
  .DateRangePickerInput_arrow_svg {
    height: 16px;
    width: 16px;
  }
}

.list {
  overflow: auto;
}
.list > * {
  margin-bottom: 2rem;
}

.modal-header {
  background-color: #0029FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.modal-header h5 {
  font-size: 2.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.modal-content {
  width: 60rem;
  border: none;
  border-radius: 3rem;
}
@media (max-width: 37.5em) {
  .modal-content {
    width: 98vw;
  }
}
.modal-body {
  max-height: 60rem;
  overflow: auto;
  background-color: white;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}
@media (max-width: 37.5em) {
  .modal-body {
    max-height: none;
  }
}
@media (max-width: 56.25em) {
  .modal-body {
    max-height: none;
  }
}
.modal__close {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  font-size: 2.2rem;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  z-index: 100;
  font-weight: 100;
}
.modal__close--black {
  color: black;
}
.modal__close:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s;
}

.main__nav__modal {
  background-color: #0029FF;
  width: 100%;
  height: 100%;
}
.main__nav__modal__body {
  background-color: #0029FF;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
}
@media (max-width: 37.5em) {
  .main__nav__modal__body {
    height: auto;
    position: relative;
  }
}
.main__nav__modal__auth {
  display: flex;
  position: absolute;
  right: 12rem;
  top: 5rem;
  width: 17rem;
  justify-content: space-between;
}
.main__nav__modal .modal__nav, .main__nav__modal .modal__nav .modal-content {
  background-color: #0029FF;
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100%;
}
.main__nav__modal .modal__nav .modal-content {
  margin: 0;
  position: relative;
}
.main__nav__modal .modal__nav .modal-content .modal-header {
  border-bottom: 0px;
  margin-top: 4rem;
}
.main__nav__modal .modal__nav .modal-content .modal-header h5 {
  font-size: 1.5rem;
}
.main__nav__modal .modal__nav .modal-content .modal-header .modal__close {
  position: absolute;
  top: 4rem;
  right: 4rem;
  font-size: 3rem;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}
.main__nav__modal .modal__nav .modal-content .modal-body {
  width: 100%;
  color: #fff;
  position: absolute;
  top: 10%;
}
@media (max-width: 37.5em) {
  .main__nav__modal .modal__nav .modal-content .modal-body {
    position: relative;
  }
}
.main__nav__modal .modal__nav .modal-content .modal-body .navbar__link .modal__nav__item {
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  text-decoration: none;
}
.main__nav__modal .modal__nav .modal-content .modal-footer {
  border-top: 0px;
  z-index: 10000;
  position: fixed;
  bottom: 0;
}

.modal__nav__item {
  margin-bottom: 6rem;
}

.navbar {
  font-family: "trap";
  width: 100%;
  padding: 2rem 4rem;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  .navbar {
    padding: 1.8rem 2rem;
  }
}
.navbar > div {
  width: 50%;
  padding-left: 2rem;
}
.navbar__logo {
  width: 20rem;
  margin-right: 4rem;
  margin-left: 2rem;
}
@media (max-width: 37.5em) {
  .navbar__logo {
    margin-right: -4rem !important;
    margin: 0;
    width: 15rem;
  }
}
.navbar__buffer {
  margin-bottom: 7rem;
}
.navbar__section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
}
@media (max-width: 37.5em) {
  .navbar__section {
    padding-right: 0;
  }
}
@media (max-width: 1153px) {
  .navbar__section {
    width: auto;
  }
}
.navbar__section > * {
  margin: 0;
}
@media (max-width: 37.5em) {
  .navbar__section {
    width: auto;
    margin-right: 0;
  }
}
.navbar__section--right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 480px) {
  .navbar__section > p {
    display: none;
  }
}
.navbar__section > .login__link {
  font-size: 2rem;
}
.navbar__section > .dropdown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__section > .launch__btn, .navbar__section > .dropdown .dropdown__btn {
  background: #9FFFBC;
  color: #000000;
  text-align: center;
  padding: 8px;
  border: 3px solid #000000;
  box-shadow: -5px 5px 1px #000000;
  width: 30%;
  border-radius: 33px;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "trap";
}
@media (max-width: 780px) {
  .navbar__section > .launch__btn, .navbar__section > .dropdown .dropdown__btn {
    width: 60%;
    padding: 7px 6px;
  }
}
.navbar__section > img {
  width: 35px;
}
.navbar__auth {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}
@media (max-width: 37.5em) {
  .navbar__auth {
    display: none;
  }
}
.navbar__auth button:hover {
  background-color: white;
  border: 1px solid #0029FF;
  transition: 0.3s;
  color: #0029FF;
}
.navbar__link {
  display: contents;
}
.navbar__menu {
  padding: 2rem;
  margin-left: 1rem;
  color: #0029FF;
}
@media (max-width: 37.5em) {
  .navbar__menu {
    margin-right: 3rem;
    padding: 2rem 0;
  }
}
.navbar__menu:hover {
  cursor: pointer;
  scale: 1.1;
  transition: 0.3s;
}
.navbar__menu img {
  width: 4rem;
}

.login__link {
  margin-right: 4rem;
}
.login__link:hover {
  cursor: pointer;
}

.pagination__item {
  color: #0029FF;
  padding: 0.5rem 1rem;
  border: solid 2px #0029FF;
  font-weight: 900;
}
.pagination__item:not(:last-child) {
  border-right: none;
}
.pagination__item:hover {
  cursor: pointer;
}
.pagination__item--active {
  background-color: #1F29BA;
}
.pagination__item--active:hover {
  background-color: #1F29BA !important;
  cursor: default;
}
.pagination__item--inactive {
  color: #8a8787 !important;
}
.pagination__item--inactive:hover {
  background-color: white !important;
  cursor: default;
}
.pagination__link {
  font-weight: 900;
  font-size: 1.3rem;
}

.radio__group {
  display: flex;
  justify-content: space-around;
}
.radio__btn-group {
  display: flex;
}
.radio__input {
  display: none;
}
.radio__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
}
.radio__label--ko {
  color: white;
}
.radio__heading {
  font-weight: 600;
  margin-bottom: 2rem;
  line-height: 2;
}
.radio__button {
  height: 1.6rem;
  width: 1.6rem;
  border: 2px solid black;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: 0;
}
.radio__button::after {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #310014;
  opacity: 0;
  transition: opacity 0.2s;
}
.radio__button:hover {
  background-color: rgba(49, 0, 20, 0.3);
  border: 2px solid #310014;
}
.radio__button--ko {
  border: 2px solid white;
}
.radio__button--ko::after {
  background-color: #1F29BA;
}
.radio__button--ko:hover {
  background-color: rgba(31, 41, 186, 0.3);
  border: 2px solid #1F29BA;
}
.radio__button--alt {
  border: 5px solid #1F29BA;
}
.radio__button--alt::after {
  background-color: #1F29BA;
}
.radio__input:checked ~ .radio__label .radio__button::after {
  opacity: 1;
}

.seat {
  height: 0.8rem;
  width: 1rem;
  margin-right: 5px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0029FF;
}
.seat--wc {
  width: 1.8rem;
}
.seat--selected {
  width: 1.8rem;
  fill: #0029FF;
}
.seat:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.seat--sm {
  height: 1rem;
  width: 2.2rem;
  margin-right: 2px;
  margin-bottom: 1rem;
  color: #0029FF;
}
.seat--sm:hover {
  cursor: pointer;
}
.seat--blank {
  visibility: hidden;
}
.seat--blank:hover {
  cursor: default;
}
.seat--taken {
  color: #707070;
}
.seat--taken:hover {
  cursor: default !important;
}
.seat__stage {
  background-color: #EDEDED;
  font-family: "portrait-light";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 2rem;
  opacity: 0.8;
  font-weight: 700;
  width: 40%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 10rem;
}
@media (max-width: 600px) {
  .seat__stage {
    width: 50rem !important;
  }
}
.seat-st__color--available {
  background-color: #AB773E;
}
.seat-st__color--unavailable {
  background-color: #EDEDED !important;
}
.seat-st__color--ada {
  background-color: black;
}
.seat-ch__color--available {
  background-color: #AB773E;
}
.seat-ch__color--unavailable {
  background-color: #EDEDED !important;
}
.seat-ch__color--unavailable:hover {
  cursor: default !important;
}
.seat-ch__color--ada {
  background-color: black;
}
.seat-ch__color-selected {
  margin-right: 5px;
  padding: 0 3px;
  margin-bottom: 1rem;
}
@media (max-width: 56.25em) {
  .seat-ch__color-selected {
    margin-right: 0;
    padding: 0 1px;
  }
}
@media (max-width: 56.25em) {
  .seat-ch__color-selected > * {
    height: 1.5rem;
    width: 1.5rem;
  }
}
.seat-ch__color-selected:hover {
  cursor: pointer;
  transition: 0.2s;
  transform: scale(1.05);
  opacity: 0.8;
}
.seat-ch__color-selected--available {
  fill: #AB773E;
}
.seat-ch__color-selected--ada {
  fill: black;
}
.seat-palace__color--available {
  background-color: #750D37;
}
.seat-palace__color--unavailable {
  background-color: #EDEDED !important;
}
.seat-palace__color--unavailable:hover {
  cursor: default !important;
}
.seat-palace__color--ada {
  background-color: black;
}
.seat-palace__color--wc {
  background-color: #0B622E;
}
.seat-palace__color-selected {
  margin-right: 2px;
  padding: 0 1px;
}
@media (max-width: 56.25em) {
  .seat-palace__color-selected {
    margin-right: 0;
    padding: 0 1px;
  }
}
.seat-palace__color-selected > * {
  height: 1.5rem;
  width: 1.5rem;
}
.seat-palace__color-selected:hover {
  cursor: pointer;
  transition: 0.2s;
  transform: scale(1.05);
  opacity: 0.8;
}
.seat-palace__color-selected--available {
  fill: #750D37;
}
.seat-palace__color-selected--ada {
  fill: black;
}

.select {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}
@media (max-width: 37.5em) {
  .select {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

select {
  padding: 1rem 2rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
}
select:hover {
  cursor: pointer;
}

option {
  font-family: "Montserrat", sans-serif;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  border: solid black;
  border-width: 0 1px 1px 0;
  padding: 2.5px;
  transform: rotate(45deg);
  margin-left: 1.5rem;
  vertical-align: middle;
}
.caret.asc {
  transform: rotate(-135deg);
}
.caret.desc {
  transform: rotate(45deg);
}
.caret.hidden {
  visibility: hidden;
}

.show-banner {
  background-size: cover;
  width: 100vw;
  height: 95rem;
  position: relative;
}
@media (max-width: 37.5em) {
  .show-banner {
    padding: 0 4rem;
  }
}
.show-banner__overlay {
  background-color: rgba(90, 90, 90, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.show-banner__heading {
  color: white;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-size: 8rem;
  position: relative;
  z-index: 10;
  top: 10rem;
  font-weight: 700;
  max-width: 50%;
  margin: 0 auto;
  line-height: 1.2;
  margin-bottom: 2rem;
}
@media (max-width: 37.5em) {
  .show-banner__heading {
    font-size: 6rem;
    max-width: 95%;
  }
}
.show-banner__desc {
  font-size: 2rem;
  color: white;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  position: relative;
  z-index: 10;
  top: 10rem;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 4rem;
}
@media (max-width: 37.5em) {
  .show-banner__desc {
    font-size: 1.6rem;
    max-width: 85%;
  }
}
.show-banner__btn {
  background-color: #e37c6d;
  padding: 2rem 18rem;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
}
.show-banner__btn:hover {
  background-color: #9ccde2;
  transition: 0.2s;
}
@media (max-width: 37.5em) {
  .show-banner__btn {
    padding: 2rem 14rem;
  }
}
.show-banner__btn--container {
  position: relative;
  z-index: 10;
  top: 10rem;
  display: flex;
  justify-content: center;
}
.show-banner__date {
  background-color: #d5d5d5;
  color: black;
  border-radius: 0;
  width: 100%;
  font-size: 2rem;
}
.show-banner__date:hover {
  cursor: pointer;
  background-color: #A2A2A2;
  transition: 0.2s;
  text-decoration: none !important;
}
.show-banner__date *:hover {
  text-decoration: none !important;
}

.show-card {
  width: 30.5rem;
  height: 34rem;
  border-radius: 2rem;
  overflow-y: auto;
  position: relative;
  border: #EDEDED solid 1px;
  box-shadow: 0px 3px 3px 0px rgba(39, 45, 59, 0.2);
  margin: 0 4rem 4rem 0;
}
@media (max-width: 37.5em) {
  .show-card {
    margin: 0 0 4rem 0;
  }
}
.show-card__header {
  width: 100%;
  height: 20rem;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  z-index: 2;
}
.show-card__header--title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.032rem;
}
.show-card__header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.show-card__header__overlay:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  transition: 0.2s;
}
.show-card__subtext {
  color: #8a8787;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-card__subtext--ko {
  color: #d5d5d5;
}
.show-card__subtext--red {
  color: red;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.show-card__footer {
  max-height: 14rem;
}
.show-card__add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.show-card__add--btn {
  font-size: 3rem;
  color: white;
  background-color: black;
  border-radius: 50%;
  width: 5.4rem;
  height: 5.4rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px black solid;
}
.show-card__add--text {
  max-width: 12rem;
}
.show-card__add:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  color: #0029FF;
  transition: 0.2s;
  cursor: pointer;
}
.show-card__add:active, .show-card__add:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 0.7rem rgba(0, 0, 0, 0.2);
}
.show-card__delete {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.show-card__description {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem;
  height: 20rem;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}
.show-card__description:hover {
  opacity: 1;
  transition: 0.4s;
}
.show-card__info {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.show-card__datetime {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}
.show-card__date {
  display: flex;
  align-items: center;
  font: "Montserrat", sans-serif;
  flex: 0 1 auto;
  background-color: #E1F2FF;
  padding: 0.3rem;
  border-radius: 0rem 0rem 0.3rem 0.3rem;
}
.show-card__date--container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: -2rem;
  z-index: 4;
}
.show-card__date--header {
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  background-color: #CBE9FF;
  width: 100%;
  height: 1.3rem;
}
.show-card__date--day {
  font-size: 2.2rem;
}
.show-card__date--text {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.show-card__date--weekday {
  color: #8a8787;
  margin-top: 0.2rem;
}
.show-card__date--month {
  margin-top: -0.6rem;
}
.show-card__time {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  display: inline-block;
  color: #8a8787;
}
.show-card__manage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.show-card__status {
  border-radius: 1.7rem;
  padding: 0.2rem 1.5rem;
  font-size: 1.4rem;
}
.show-card__status--active {
  color: #7CB484;
  background-color: rgba(138, 211, 149, 0.41);
}
.show-card__status--draft {
  color: white;
  background-color: #8a8787;
}
.show-card__status--past {
  color: white;
  background-color: rgba(0, 0, 0, 0.73);
}
.show-card__shows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar {
  background-color: #0029FF;
  flex: 0 0 18%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  transition: width 0.3s;
}
.sidebar__spacer {
  width: 17rem;
  height: 100vh;
  flex-shrink: 0;
}
@media (max-width: 37.5em) {
  .sidebar__spacer {
    width: 13rem;
  }
}
@media (max-width: 28.5em) {
  .sidebar__spacer {
    width: 8rem;
  }
}
.sidebar__spacer--sm {
  width: 5rem;
  background-color: #F6F7FF;
}

.sidenav {
  font-size: 1.4rem;
  list-style: none;
  margin-top: 3.5rem;
  color: white;
}
.sidenav__container {
  background-color: #0029FF;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  transition: width 0.3s;
}
.sidenav__container--closed {
  background-color: transparent;
}
.sidenav__container.open {
  width: 17rem;
}
.sidenav__container.closed {
  width: 5rem;
}
.sidenav__logo {
  width: 5rem;
  height: 7rem;
}
.sidenav__logo--closed {
  width: 3rem;
}
.sidenav__logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.sidenav__logo-container--closed {
  padding: 2rem 0.5rem;
}
@media (max-width: 37.5em) {
  .sidenav__logo-container {
    padding: 1rem;
  }
}
.sidenav__toggle-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
  margin-top: 4rem;
}
.sidenav__toggle-button--open {
  color: #0029FF;
}
.sidenav__item {
  position: relative;
  color: #A2A2A2;
}
@media (max-width: 37.5em) {
  .sidenav__item {
    width: 100%;
  }
}
@media only screen and (max-width: 56.25em) {
  .sidenav__item {
    flex: 1;
  }
}
.sidenav__item:not(:last-child) {
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 56.25em) {
  .sidenav__item:not(:last-child) {
    margin-bottom: 0;
    width: 100%;
  }
}
.sidenav__item:active::before {
  background-color: var(--color-primary);
}
.sidenav__item--active {
  font-weight: 900;
  color: white;
}
.sidenav__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: var(--color-primary);
  transform: scaleY(0);
  transition: transform 0.4s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
}
.sidenav__item:hover::before, .sidenav__item--active::before {
  transform: scaleY(1);
  width: 100%;
}
.sidenav__link:link, .sidenav__link:visited {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  padding: 1.5rem 3rem;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 56.25em) {
  .sidenav__link:link, .sidenav__link:visited {
    justify-content: center;
  }
}
@media only screen and (max-width: 37.5em) {
  .sidenav__link:link, .sidenav__link:visited {
    flex-direction: column;
    padding: 1.5rem 0.5rem;
  }
}
.sidenav__icon {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 2rem;
  margin-top: -0.75rem;
  fill: currentColor;
}
.sidenav__icon svg {
  width: 2rem;
  height: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .sidenav__icon {
    margin-right: 0;
    margin-bottom: 0.7rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.categories--menu-container {
  position: relative;
  width: 100%;
}

.categories {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.category {
  position: relative;
}

ul {
  list-style: none;
}

.menu > li > a {
  display: block;
  padding: 0.7rem 1rem;
  padding-top: 0.7rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  line-height: 1;
}

/*content*/
.category--content {
  position: absolute;
  top: 50px;
  white-space: nowrap;
  right: 0;
  transform: translateX(100%);
}

.category--content h2 {
  text-align: left;
  font-size: 44px;
  transform: translateX(-50px);
  text-transform: uppercase;
  font-weight: bold;
}

.category--content .project-list {
  padding: 0 0 0 30px;
}

.category--content,
.category--content .project-list,
.category--content .project-list .category {
  text-align: left;
}

.category--name {
  position: absolute;
  white-space: nowrap;
  width: calc(100% - 5px);
  transition: all 0.5s ease;
  padding-top: 5px;
  margin-top: 15px;
  margin-right: 5px;
  border-top: 1px solid #666;
  color: #666;
}

.category--name h6 {
  font-size: 16px;
  color: inherit;
  text-transform: capitalize;
}

.focused .category--name {
  opacity: 0;
}

.projectlist--client {
  font-size: 18px;
  margin-bottom: 0px;
  -webkit-text-stroke: 0;
  text-transform: capitalize;
  font-weight: bold;
}

.projectlist--byline {
  font-size: 12px;
  font-family: "Nothing You Could Do", sans-serif;
}

/*last item content*/
.isLast .category--content {
  right: initial;
  left: 0;
  transform: translateX(-100%);
}

.isLast .category--content h2 {
  transform: translateX(50px);
  text-align: right;
}

.isLast .category--content .project-list {
  padding: 0 30px 0 0;
}

.isLast .category--content,
.isLast .category--content .project-list,
.isLast .category--content .project-list .category {
  text-align: right;
}

/*inactive states*/
.category {
  transition: transform 500ms ease;
  position: relative;
  z-index: 1;
}

.category--content {
  text-align: right;
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
}

.category--content .project-list .category {
  opacity: 0;
  transition: all 300ms ease;
}

.category--image-container {
  background: #222;
  transition: all 500ms ease;
}

/*active states*/
.category.isActive {
  z-index: 99;
}

.isActive .category--content {
  z-index: 99;
  visibility: visible;
  pointer-events: all;
}

.isActive .category--content .project-list .category {
  opacity: 1;
}

/*image states*/
.category--image:hover, .category--image:focus, .focused .category--image:hover, .focused .category--image:focus {
  opacity: 1;
  cursor: pointer;
}

.category--image {
  opacity: 0.7;
  transition: opacity 500ms ease, width 500ms ease 100ms;
}

.focused .category--image {
  opacity: 0.25;
}

.focused .isActive .category--image {
  opacity: 0.75;
}

.category--closeButton {
  opacity: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -5rem;
  transition: opacity 200ms ease;
}

.focused .isActive .category--closeButton {
  opacity: 1;
  z-index: 99;
}

.category--closeButton a {
  margin: -15px;
  padding: 15px;
}

/*hover state*/
.category--image {
  /*transition: width 500ms ease;*/
}

.categories--menu-container:not(.focused) .category:hover .category--name {
  border-color: #eee;
  color: #eee;
}

/*Nav*/
.right-arrow {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: inline-block;
}

.left-arrow {
  position: fixed;
  bottom: 50px;
  left: 50px;
  display: inline-block;
}

.left-arrow a, .right-arrow a {
  background-color: #222;
  z-index: 9;
  position: relative;
  padding: 5px;
  font-size: 12px;
}

.right-arrow img, .left-arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  z-index: 0;
  width: 140px;
  max-width: none;
}

.left-arrow img {
  transform: translate(-50%, -50%) scaleX(-1);
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
.c-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.c-table th, .c-table td {
  padding: 1rem 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 37.5em) {
  .c-table th, .c-table td {
    padding: 0.5rem 0.3rem;
    font-size: 1rem;
  }
}
.c-table th {
  color: #0029FF;
  font-weight: 500;
  font-size: 1.4rem;
}
@media (max-width: 37.5em) {
  .c-table th {
    font-size: 1.2rem;
  }
}
.c-table thead tr:first-child th:first-child {
  border-top-left-radius: 1.5rem;
}
.c-table thead tr:first-child th:last-child {
  border-top-right-radius: 1.5rem;
}
.c-table__row {
  width: 100%;
  margin-bottom: 2rem;
  background-color: white;
}
.c-table__row:not(:last-child) {
  border-bottom: 1.5px solid #d5d5d5;
}
.c-table__d {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #8A8A8A;
  padding: 2rem 0.5rem;
}
@media (max-width: 37.5em) {
  .c-table__d {
    padding: 0.5rem 0.3rem;
    font-size: 1rem;
  }
}
.c-table__d__email {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .c-table__d__email {
    justify-content: flex-start;
  }
}
.c-table__d__email .icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .c-table {
    overflow-x: auto;
    display: block;
  }
}

.tabular {
  display: flex;
}
@media (max-width: 37.5em) {
  .tabular {
    flex-direction: column;
  }
}
.tabular__tabs {
  display: flex;
  flex-direction: column;
  width: 14rem;
  margin-right: 4rem;
}
@media (max-width: 37.5em) {
  .tabular__tabs {
    flex-direction: row;
  }
}
.tabular__tab {
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #8a8787;
  border-right: 1px solid #d3d3d3;
}
.tabular__tab:hover {
  background-color: #e0e0e0;
  cursor: pointer;
  transition: 0.2s;
}
.tabular__tab--active {
  color: black;
  font-weight: 700;
}
.tabular__link {
  text-decoration: none;
  display: block;
  width: 100%;
}
.tabular__content {
  padding: 2rem;
  flex-grow: 1;
  box-shadow: 0px 25px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #F6F7FF;
  overflow: scroll;
}

.toggle__container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.toggle__container input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggle__slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #0029FF;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .toggle__slider {
  background-color: black;
}
input:focus + .toggle__slider {
  box-shadow: 0 0 1px black;
}
input:checked + .toggle__slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle__slider.toggle__round {
  border-radius: 34px;
}

.toggle__slider.toggle__round:before {
  border-radius: 50%;
}

.__react_component_tooltip {
  background-color: black !important;
}
.__react_component_tooltip::after {
  border-top-color: black !important;
}
.__react_component_tooltip::before {
  border-top-color: black !important;
}

.ticker {
  background-color: black;
  color: white;
  padding: 1rem 0;
  height: 5rem;
  width: 100%;
  white-space: nowrap;
  font-size: 1.6rem;
  font-family: "Work Sans";
  font-weight: 700;
  position: relative;
  overflow: hidden;
}
.ticker-text {
  width: 100%;
  position: absolute;
  animation: scroll 12s linear infinite;
  max-width: 100vw;
}
.ticket-manager__header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}
.ticket-manager__discounts {
  padding: 1rem 2rem;
}
.ticket-manager__row {
  width: 100%;
}
.ticket-manager__row th:first-child,
.ticket-manager__row td:first-child {
  width: 3.5rem;
  white-space: nowrap;
  min-width: fit-content;
}
.ticket-manager__icons {
  display: flex;
}
.ticket-manager__icons > * {
  margin: 0 1rem;
}
.ticket-manager__icon:hover {
  cursor: pointer;
}
.ticket-manager__edit {
  padding: 2rem;
}

.tts {
  display: flex;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif;
  font-size: 1.6rem;
}
.tts__desc {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}
.tts__type {
  font-weight: 700;
}
.tts__category {
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
  font-size: 1.6rem;
}
.tts__subcat {
  display: flex;
  justify-content: space-between;
}
.tts__subcat > *:not(:last-child) {
  margin-bottom: 2rem;
}
.tts__subcat-container {
  display: flex;
  flex-direction: column;
}
.tts__btns {
  display: flex;
  align-items: center;
}
.tts__btns > *:not(:last-child) {
  margin-right: 1rem;
}
.tts__btn {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: none;
  background-color: #0029FF;
}
.tts__btn:hover {
  cursor: pointer;
}
.tts__btn--inactive {
  background-color: #8a8787;
}
.tts__btn--inactive:hover {
  cursor: default;
}
.tts__btn--inactive:active {
  outline: none;
}
.tts__btn--inactive:focus {
  outline: none;
}
.tts__totals {
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

.floorplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
.floorplan__section {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.floorplan__section__wrapper {
  position: relative;
}
.floorplan__section__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.floorplan__section__cover:hover {
  cursor: pointer;
  background-color: rgba(0, 41, 255, 0.1);
  transition: 0.4s;
}
.floorplan__section__back {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.floorplan__section--mobile {
  transform: scale(0.8);
}
.floorplan__transform {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.floorplan__transform__wrapper {
  width: 100%;
  height: 100%;
  max-width: 100vw;
}
.floorplan__container {
  width: 100%;
  position: relative;
  min-height: 30rem;
}
.floorplan__loading {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floorplan__zoom__tools {
  position: absolute;
  z-index: 2;
  right: 1rem;
  top: 1rem;
  display: flex;
  flex-direction: column;
}
.floorplan__zoom__tools button {
  margin-bottom: 1rem;
}

.react-transform-wrapper {
  width: 90% !important;
}

.footer {
  box-sizing: border-box;
  background-color: transparent;
  padding: 4rem 4rem;
  font-size: 1.4rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
}
@media (max-width: 680px) {
  .footer__sm_flex_col {
    flex-direction: column;
  }
}
.footer__right {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  width: 70%;
  justify-content: space-evenly;
}
@media (max-width: 37.5em) {
  .footer__right > * {
    margin: 0 1rem;
  }
}
@media (max-width: 680px) {
  .footer__right {
    width: 100%;
    margin-top: 0;
  }
}
.footer__logo-box {
  text-align: center;
  position: relative;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
  width: 25%;
}
@media (max-width: 680px) {
  .footer__logo-box {
    width: fit-content;
    justify-content: center;
  }
}
.footer__logo {
  width: min(20rem, 100%);
  height: auto;
}
@media (max-width: 37.5em) {
  .footer__logo {
    margin-bottom: 3rem;
  }
}
.footer__white {
  color: #fff;
  justify-content: space-between;
}
@media (max-width: 680px) {
  .footer__white > .footer__logo-box {
    width: 30%;
  }
}
@media (max-width: 680px) {
  .footer__white > .footer__right {
    width: 55%;
  }
}
@media (max-width: 680px) {
  .footer__white > .footer__right > .sm_d_none {
    display: none;
  }
}

.grid__container {
  width: 80%;
  margin: auto;
}

.result-message {
  color: #d5d5d5;
}

.theater {
  max-width: 100vw;
  overflow: hidden;
}
.theater__nav {
  position: relative;
}
.theater__hero {
  position: relative;
  margin-bottom: 10rem;
  width: 100vw;
  height: 60vh;
}
@media (max-width: 37.5em) {
  .theater__hero {
    margin-bottom: 4rem;
  }
}
.theater__hero--bg {
  filter: blur(40px);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.theater__hero--icon {
  width: 1.5rem;
  margin-right: 0.5rem;
}
@media (max-width: 37.5em) {
  .theater__hero {
    padding: 0 1rem;
  }
}
.theater__hero--container {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.5em) {
  .theater__hero--container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
.theater__hero-heading {
  text-align: left;
  line-height: 1.3;
  font-weight: 700;
}
@media (max-width: 37.5em) {
  .theater__hero-heading {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}
.theater__hero-text {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
  line-height: 1.5;
}
.theater__hero--left {
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 37.5em) {
  .theater__hero--left {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    padding: 4rem 2rem 0;
    margin-right: 0;
  }
}
.theater__hero--btn {
  width: 80%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theater__hero--btn-container {
  display: flex;
  justify-content: center;
}
.theater__hero--btm {
  margin-top: 3rem;
}
.theater__buy-tix {
  margin-top: 4rem;
}
@media (max-width: 37.5em) {
  .theater__buy-tix {
    margin-top: 2rem;
  }
}
.theater__btn {
  padding: 1.5rem 3rem;
  font-weight: 500;
}
.theater__poster {
  max-width: 50rem;
  max-height: 50rem;
  border-radius: 3rem;
  margin-top: auto; /* Let margin grow to center the element */
  margin-bottom: auto; /* Center the element vertically */
  margin-right: 6rem;
}
@media (max-width: 1451px) {
  .theater__poster {
    max-width: 50rem;
    max-height: 50rem;
  }
}
@media (max-width: 37.5em) {
  .theater__poster {
    margin-top: 0;
  }
}
.theater__poster--container {
  height: 100%;
  display: contents;
}
@media (max-width: 37.5em) {
  .theater__poster--container {
    margin-right: 0;
  }
}
.theater__logo--sm {
  width: 10rem;
  margin-top: -1rem;
}
.theater__overview {
  margin-right: 8rem;
}
@media (max-width: 37.5em) {
  .theater__overview {
    margin-right: 0;
  }
}
.theater__overview__text {
  flex: 2;
}
.theater__info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 4rem;
}
@media (max-width: 56.25em) {
  .theater__info {
    flex-direction: column;
  }
}
.theater__cart {
  border-bottom: #EDEDED 1px solid;
  width: 20rem;
  overflow-x: auto;
}
.theater__cart__seat {
  margin-bottom: 0 !important;
  margin-right: 2rem !important;
}
.theater__cart__btn {
  padding: 1.5rem 0;
  width: 100%;
}
.theater__floorplan-container {
  overflow-x: scroll;
  padding: 0 8rem;
}
@media (max-width: 37.5em) {
  .theater__floorplan-container {
    padding: 0 1rem;
  }
}

.admin__filters {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 4rem 2rem 2rem;
  justify-content: flex-start;
}
.admin__filters__section {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .admin__filters__section {
    margin: 4rem 0;
  }
}
.admin__filters__section {
  margin-right: 2rem;
}
.admin__filters__section select {
  width: 27rem;
}
.admin__charts {
  display: flex;
  flex-wrap: wrap;
}
.admin__charts div {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.create-event {
  margin: 2rem;
  padding: 4rem;
  background-color: rgba(244, 244, 244, 0.62);
  border-radius: 2rem;
}
.create-event__heading {
  font-size: 3rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
}
.create-event__1 {
  display: flex;
  justify-content: space-between;
}
.create-event__1--left {
  width: 75%;
  margin-right: 4rem;
}
.create-event__1--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.create-event__1--img-upload {
  display: flex;
}

.dash-home__heading {
  font-size: 4rem;
  font-weight: 900;
}
.dash-home__financial {
  margin-top: 4rem;
}
.dash-home__stripe-sign-up {
  margin-top: 4rem;
}
.dash-home__charts {
  display: flex;
  padding-bottom: 2rem;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 37.5em) {
  .dash-home__charts {
    flex-direction: column;
    align-items: center;
  }
}
.dash-home__chart {
  height: 37rem;
  width: 45rem;
  background-color: white;
  border-radius: 3rem;
  padding: 3rem 3rem 6rem;
}
@media (max-width: 37.5em) {
  .dash-home__chart {
    width: 100%;
    margin-bottom: 4rem;
    margin-right: 0;
  }
}
@media (max-width: 75em) {
  .dash-home__chart {
    margin-right: 2rem;
  }
}
.dash-home__chart__title {
  color: #A2A2A2;
}
.dash-home__chart__total {
  font-weight: 700;
}
.dash-home__orders {
  padding-right: 2rem;
  margin-top: 4rem;
}
@media (max-width: 37.5em) {
  .dash-home__orders {
    overflow-x: scroll;
    padding-right: 0;
  }
}

.event__preview {
  width: 100%;
  background: var(--slicktix-gradiant-1, linear-gradient(90deg, #9BC9FF 0%, #D59EEF 55%, #FF7FE3 100%));
  padding: 1rem 0;
  text-align: center;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
}
.event__hero {
  height: 60vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 8rem;
}
@media (max-width: 37.5em) {
  .event__hero {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
}
.event__hero__bg {
  filter: blur(40px);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.event__hero__poster {
  max-width: 40rem;
  max-height: 50rem;
  border-radius: 1rem;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
  margin-right: 8rem;
}
@media (max-width: 37.5em) {
  .event__hero__poster {
    margin-right: 0;
    margin-bottom: 4rem;
    align-self: center;
  }
}
.event__hero__content {
  position: relative;
  z-index: 1;
  max-width: 40%;
}
@media (max-width: 37.5em) {
  .event__hero__content {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}
.event__hero h3 {
  font-size: 5.6rem;
  font-family: trap;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1.4rem;
}
.event__hero p {
  margin-bottom: 1.4rem;
  font-family: "Montserrat", sans-serif;
}
.event__hero p svg {
  width: 1.2rem;
  margin-right: 1rem;
  fill: #3e4642;
}
.event__hero button {
  margin-top: 2rem;
  color: #F1F1F1;
  font-weight: 500;
  padding: 1.5rem 3rem;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}
.event__select {
  width: 100%;
  padding: 2rem 4rem 0;
}
@media (max-width: 37.5em) {
  .event__select {
    margin-top: 4rem;
  }
}
.event__select__header {
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  width: 100%;
  padding-bottom: 2rem;
}
.event__select__header .p__1 {
  font-size: 2.4rem;
}
.event__select__tickets {
  padding: 2rem;
}
.event__body {
  display: flex;
  justify-content: space-between;
  padding: 0 14rem;
}
@media (max-width: 37.5em) {
  .event__body {
    flex-direction: column;
    padding: 0 2rem;
    align-items: center;
  }
}
.event__body--left {
  width: 45%;
  margin-right: 8rem;
}
.event__body--left .p__2 p {
  line-height: 1.7 !important;
}
@media (max-width: 37.5em) {
  .event__body--left {
    width: 100%;
    margin-right: 0;
  }
}
.event__body--right {
  border-radius: 4.1rem;
  max-width: 40%;
  border: 1px solid #000;
  padding: 4rem 0;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.56);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
}
@media screen and (max-width: 750px) {
  .event__body--right {
    max-width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 37.5em) {
  .event__host {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
.event__host h5 {
  font-size: 2rem;
  font-weight: 700;
}
.event__host p {
  font-size: 1.6rem;
}
.event__totals {
  margin: 2rem 0;
}
.event__modal {
  left: 5%;
  margin: 5rem 0 0 0;
}
.event__modal .modal-content {
  width: 90vw;
}
.event__floorplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}
@media (max-width: 37.5em) {
  .event__continue {
    margin-top: 2rem;
  }
}
.event__payment-form {
  width: 100%;
}

.event-manager__banner {
  display: flex;
  margin-bottom: 2rem;
}
@media (max-width: 37.5em) {
  .event-manager__banner {
    flex-direction: column;
  }
}
.event-manager__banner span {
  margin-right: 1rem;
}
.event-manager__poster {
  max-height: 20rem;
  border-radius: 2rem;
  box-shadow: 0px 3px 3px 0px rgba(39, 45, 59, 0.2);
  margin-right: 2rem;
}
@media (max-width: 37.5em) {
  .event-manager__poster {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}
.event-manager__live {
  margin-top: 2rem;
}
.event-manager__tabs {
  margin: 2rem 0;
  width: 95%;
}
.event-manager__details {
  display: flex;
  width: 100%;
}
.event-manager__details--left {
  width: 70%;
  margin-right: 4rem;
}
.event-manager__details--right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.event-manager__details--group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.event-manager__details--subgroup {
  flex-grow: 1;
  margin-right: 2rem;
}
.event-manager__details--btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-manager__details--btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.event-manager__publish {
  padding: 2rem 4rem;
}
.event-manager__publish__checklist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-manager__publish__checklist-item p {
  color: #0029FF;
  text-decoration: underline;
}
.event-manager__publish__checklist-item p:hover {
  cursor: pointer;
}
.event-manager__publish__checklist-item span {
  color: white;
}
.event-manager__publish__checklist-item span .checklist-icon {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-manager__publish__checklist-item span .yes {
  background-color: green;
}
.event-manager__publish__checklist-item span .no {
  background-color: red;
}

.financial__wave {
  width: 100%;
  height: 50rem;
  flex-grow: 1;
}
.financial__stripe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.financial__stripe__logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5rem;
  width: 43%;
}
.financial__stripe__separator {
  width: 3px;
  height: 12rem;
  background-color: #8a8787;
}
.financial__stripe__logo {
  width: 20rem;
}
.financial__stripe__logo--pad {
  padding: 0 1.5rem;
  margin-bottom: -0.5rem;
}
.financial__filters {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 0;
}
.financial__filters--event {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.financial__filters--time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
}
.financial__filters--reset {
  background-color: transparent;
  border: none;
  color: #8a8787;
  margin-left: 2rem;
}

.landing {
  font-family: "trap";
  max-width: 100vw;
  overflow: hidden;
}
.landing__events {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.landing__events > * {
  font-family: "trap";
}
.landing__events__slider {
  width: 100vw;
  height: fit-content;
  max-height: 60vh;
}
@media (max-width: 37.5em) {
  .landing__events__slider {
    display: none;
  }
}
.landing__events > a {
  text-decoration: underline;
}
@media (max-width: 480px) {
  .landing__events > a {
    width: 100%;
  }
}
.landing__events > a h2 {
  margin: 4rem 0 0;
  padding-left: 4rem;
  font-weight: 900;
  font-family: "trap";
}
@media (max-width: 480px) {
  .landing__events > a h2 {
    text-align: center;
  }
}
.landing__events__heading {
  display: none;
}
@media (max-width: 37.5em) {
  .landing__events__heading {
    display: block;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 400;
    border-bottom: 1px solid black;
    margin: 4rem 0 2rem 4rem;
    color: black;
    letter-spacing: 1px;
  }
}
.landing__events__wrap {
  padding: 2.5rem 4rem;
  width: 100%;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  column-gap: 2.5em;
  row-gap: 2.5em;
  align-items: stretch;
}
@media (max-width: 1200px) {
  .landing__events__wrap {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
@media (max-width: 480px) {
  .landing__events__wrap {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.landing__events__wrap > div {
  min-height: 450px;
  margin-bottom: 1rem;
}
.landing__hero {
  height: 35rem;
  background-position: 10% 75%;
  background-size: cover;
  position: relative;
}
.landing__hero--overlay {
  background-color: rgba(49, 0, 20, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.landing__heading {
  position: absolute;
  left: 10%;
  top: 20%;
  z-index: 10;
}
.landing__featured {
  padding: 0 4rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}
.landing__featured > * {
  margin: 0 2rem 2rem 0;
}
.landing__about {
  padding: 20rem 4rem;
  background-size: cover;
  border: 3px solid #1F29BA;
  transform: skewY(-7deg);
  margin: 17rem 0;
}
.landing__about > * {
  transform: skewY(7deg);
  color: white;
}
.landing__carousel-item {
  height: 60vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.landing__carousel-item__bg {
  filter: blur(40px);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.landing__carousel-item__poster {
  border-radius: 1rem;
  height: 38rem;
  width: 33rem;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
  margin-right: 8rem;
}
.landing__carousel-item__content {
  position: relative;
  z-index: 1;
  max-width: 40%;
}
.landing__carousel-item h3 {
  font-size: 5.6rem;
  font-family: trap;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1.4rem;
}
.landing__carousel-item p {
  margin-bottom: 1.4rem;
  font-family: "Montserrat", sans-serif;
}
.landing__carousel-item p svg {
  width: 1.2rem;
  margin-right: 1rem;
  fill: #3e4642;
}
.landing__carousel-item button {
  margin-top: 2rem;
  color: #F1F1F1;
  font-weight: 500;
  padding: 1.5rem 3rem;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

.launch-event {
  margin: 2rem auto;
  padding: 4rem;
  background-color: rgba(240, 240, 240, 0.3);
  border: 1px solid #0029FF;
  border-radius: 2rem;
  max-width: 70vw;
}
@media (max-width: 37.5em) {
  .launch-event {
    max-width: 95%;
  }
}
.launch-event__heading {
  font-size: 3rem;
  font-family: "trap", sans-serif;
  margin-bottom: 2rem;
}
.launch-event__form {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  .launch-event__form {
    flex-direction: column;
  }
}
.launch-event__form--left {
  width: 75%;
  margin-right: 4rem;
}
@media (max-width: 37.5em) {
  .launch-event__form--left {
    width: 100%;
    margin-right: 0;
  }
}
.launch-event__form--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.launch-event__img-upload {
  display: flex;
}
@media (max-width: 37.5em) {
  .launch-event__img-upload {
    margin-bottom: 4rem;
    margin-top: 2rem;
    width: 100%;
  }
}
@media (max-width: 37.5em) {
  .launch-event .image-upload__sublabel {
    text-align: center;
  }
}
.launch-event textarea.input {
  width: 100%;
  height: 100px;
}
.launch-event__private__group {
  display: flex;
  align-items: center;
}
.launch-event__private__group label {
  margin-bottom: 0;
}
.launch-event__custom-features {
  min-width: 90%;
  max-width: 110%;
}

.maintenance {
  background-color: #0029FF;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.maintenance__logo {
  width: 40rem;
  margin: 6rem 0 25rem 0;
}
.maintenance__banner {
  color: #0029FF;
  background-color: white;
  font-family: Trap;
  font-size: 2.7rem;
  font-weight: 700;
  height: 4rem;
  width: 120vw;
}
.maintenance__banner--text {
  transform: rotate(-10.867deg);
  overflow: hidden;
}
.maintenance__banner--plain {
  transform: rotate(4.373deg);
  opacity: 0.8;
}
.maintenance__banner span {
  margin-right: 6rem;
}

.palace {
  overflow-x: scroll;
}
@media (max-width: 600px) {
  .palace {
    padding-left: 51rem;
  }
}
.palace__wrapper {
  padding: 0 2rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.palace__dash {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}
.palace__theater {
  padding-right: 4rem;
}
.palace__cart {
  display: flex;
  flex-direction: column;
  width: 20rem;
}
.palace__cart--icon {
  color: #0029FF;
  font-size: 2rem;
}
.palace__checkout {
  overflow: auto;
}
.p-reset__form {
  margin: 8rem auto;
  width: 40rem;
}

.profile {
  margin-left: 2rem;
}
.profile h1 {
  font-size: 3rem;
}
.profile input {
  max-width: 70vw;
}
.profile .input__container {
  display: flex;
  flex-direction: column;
}

.reports__production {
  display: flex;
}
.reports__shows-tabs {
  display: flex;
}
.reports__statbox {
  border: #310014 solid 3px;
  border-radius: 4px;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  color: #0029FF;
}
.reports__statbox--sm {
  border: #310014 solid 3px;
  border-radius: 4px;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
  color: #0029FF;
}
.reports__scrollbox {
  max-height: 50rem;
  overflow: auto;
  border-radius: 2px solid #0029FF;
}

.scan__result {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scan__result--success {
  background-color: #4cd964;
}
.scan__result--invalid {
  background-color: rgb(214, 47, 26);
}
.scan__result--invalid-icon {
  font-size: 6rem;
  color: white;
  font-weight: 900;
}
.scan__result--hidden {
  background-color: white;
}

.seats {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  padding: 5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
}
.seats__hero {
  height: 30rem;
  padding: 5rem;
  width: 100%;
  background-image: linear-gradient(to right top, rgba(244, 249, 255, 0.9), rgba(49, 0, 20, 0.9));
  display: flex;
}
.seats__hero--img {
  height: 100%;
  border-radius: 5px;
  opacity: 0.9;
}
.seats__confirm {
  background-color: rgba(0, 0, 0, 0.85);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem auto;
}
.seats__dude {
  margin-top: 5rem;
  width: 40rem;
  height: 40rem;
  object-fit: cover;
  border-radius: 50%;
}
.seats__farewell {
  font-family: "Sriracha", cursive;
  color: white;
  font-size: 4rem;
  margin-top: 4rem;
  width: 70%;
}
.seats__options {
  width: 100%;
  display: flex;
  justify-content: center;
}
.seats__selection {
  width: auto;
  margin: auto;
  max-width: 70%;
  overflow: scroll;
}
@media (max-width: 960px) {
  .seats__selection {
    max-width: 90%;
  }
}
.seats__checkout-button {
  border: none;
  padding: 1rem;
  border-radius: 5px;
  background-color: #0029FF;
  font-size: 1.6rem;
  font-weight: 700;
  color: white;
}
.seats__checkout-button:active {
  transform: translateY(2px);
  transition: 0.2s;
}
.seats__checkout-button:hover {
  cursor: pointer;
  transform: translateY(-2px);
  transition: 0.2s;
}
.seats__seats {
  padding: 2rem;
}
.seats__cart {
  width: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.showrunner {
  padding: 4rem 0 4rem 4rem;
  overflow: hidden;
  background-color: #F6F7FF;
  min-height: 80vh;
}
@media (max-width: 37.5em) {
  .showrunner {
    padding: 0 2rem;
  }
}
.showrunner__floorplan {
  overflow: auto;
  margin-top: 2rem;
}
.showrunner__page {
  display: flex;
}
.showrunner__container {
  width: 100%;
  overflow: hidden;
}
.showrunner__prods {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 37.5em) {
  .showrunner__prods {
    flex-direction: column;
    align-items: center;
  }
}
.showrunner__prods__separator {
  width: 90%;
  height: 1px;
  background-color: #8a8787;
  margin: 2rem 0 4rem 0;
}
.showrunner__finances .DateInput {
  background-color: transparent;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showrunner__finances .DateInput_input {
  background-color: transparent;
}
.showrunner__orders {
  width: 95%;
  height: 100%;
}
.showrunner__orders__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  .showrunner__orders__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 37.5em) {
  .showrunner__orders .DateInput {
    margin-bottom: 2rem !important;
  }
}
.showrunner__ticket-manager {
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.7);
  background-color: black;
}
.showrunner__filters {
  display: flex;
  align-items: center;
  padding: 4rem 0;
  width: 100%;
}
@media (max-width: 37.5em) {
  .showrunner__filters {
    flex-direction: column;
    align-items: flex-start;
  }
}
.showrunner__filters__section {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showrunner__filters__section select {
  width: 27rem;
}
.showrunner__filters__section .select {
  width: 27rem;
}
@media (max-width: 37.5em) {
  .showrunner__filters__section--f1 {
    width: 100%;
  }
}
@media (max-width: 37.5em) {
  .showrunner__filters__section--f2 {
    width: 88.5%;
  }
}
@media (max-width: 37.5em) {
  .showrunner__filters__section--f2 span {
    display: block;
  }
}
.showrunner__filters--event {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
}
.showrunner__filters--event select {
  max-width: 30rem;
}
.showrunner__filters--time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
  width: 37rem;
}
.showrunner__filters--reset {
  background-color: transparent;
  border: none;
  color: #8a8787;
}
.showrunner__filters--search {
  margin-right: 2rem;
  flex-grow: 1;
}
.showrunner__filters--search input {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #8A8A8A;
  font-size: 1.2rem;
}
.showrunner__refund__btn {
  border: none;
}
.showrunner select:hover {
  cursor: pointer;
}

.signup-complete {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.signup-complete__heading {
  font-size: 8rem;
  color: #0029FF;
  font-family: "trap", sans-serif;
  text-align: center;
  margin-top: 2rem;
}
.signup-complete__text {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}
.signup-complete__particles-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}
.signup-complete__particle {
  position: absolute;
  width: 50px;
  height: 20px;
  border-radius: 5px;
  opacity: 0.9;
}
.signup-complete__particle--type1 {
  background-color: #ffcc00;
  color: #ffcc00;
  fill: #ff5733;
}
.signup-complete__particle--type2 {
  background-color: #ff5733;
  fill: #ff5733;
  color: #ff5733;
}

:root {
  --ticket-container-width: 200rem;
  --ticket-container-height: calc(var(--ticket-container-width) * .77);
  --ticket-width: calc(var(--ticket-container-width)/2);
  --ticket-height: calc(var(--ticket-container-height)/5);
}

.tickets {
  position: relative;
}
.tickets__container {
  width: var(--ticket-container-width);
  height: var(--ticket-container-height);
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-start;
  flex-direction: column;
}
.tickets__null {
  height: 64.7rem;
  width: 200rem;
}
.tickets__img {
  height: var(--ticket-height);
  width: var(--ticket-width);
}
.tickets__date {
  position: absolute;
  font-family: "Cinzel", serif;
}
.tickets__date--body {
  font-size: 1.4rem;
  bottom: 3.5rem;
  left: 4rem;
}
.tickets__date--stub {
  font-size: 1.4rem;
  top: 7rem;
  right: 10.5rem;
}
.tickets__seat {
  position: absolute;
  font-family: "Cinzel", serif;
}
.tickets__seat--body {
  font-size: 2rem;
  bottom: 3rem;
  right: 37rem;
}
.tickets__seat--stub {
  font-size: 2rem;
  top: 13.5rem;
  right: 8.5rem;
}
.tickets__barcode {
  mix-blend-mode: multiply;
  position: absolute;
  width: 12rem;
  right: 4rem;
  bottom: 0rem;
  width: 27%;
}
.tickets__text {
  font-family: "Cinzel", serif;
  font-size: 1.8rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tickets__text--black {
  color: black;
  bottom: -0.6rem;
  right: 20.3rem;
}
.tickets__text--white {
  color: white;
  top: 7.8rem;
  left: -4rem;
}
.tickets__dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 1rem;
}
.tickets__dot--black {
  background-color: #000000;
}
.tickets__dot--white {
  background-color: white;
}

.willcall {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 4rem;
}
.willcall__background {
  background-color: #ecf1fd;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}
.willcall h1 {
  font-family: "trap";
  font-size: 6rem;
  color: #2029BA;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.willcall__downloads {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.willcall__downloads-item {
  margin: 0 2rem;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}
.willcall__downloads-item svg {
  font-size: 3rem;
  color: #8a8787;
  margin-right: 1rem;
}
.willcall__downloads-item p {
  font-size: 1.4rem;
}
.wwu * {
  font-family: "trap", sans-serif;
}
.wwu__nocursor {
  cursor: none;
}
.wwu__nocursor button:hover, .wwu__nocursor a:hover, .wwu__nocursor .login__link:hover {
  cursor: none !important;
  color: #0029FF;
}
.wwu__show-cursor {
  cursor: auto;
}
.wwu button:hover {
  cursor: none;
  color: white;
  background-color: #0029FF;
  transition: 0.4s;
}
.wwu__section1 {
  padding: 4rem 0 40.3rem 0;
}
@media (max-width: 75em) {
  .wwu__section1 {
    margin-bottom: 0;
  }
}
.wwu__section1--content {
  text-align: center;
}
.wwu__section1--cta {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}
.wwu__section1--cta p {
  font-size: 3rem;
  max-width: 70%;
  line-height: 2;
  text-align: center;
  color: #e288e4;
}
.wwu__section1--cta p span {
  color: #0029FF;
}
.wwu__section1--imgs {
  width: 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wwu__section1--imgs-container {
  position: relative;
}
@media (max-width: 37.5em) {
  .wwu__section1--imgs-container {
    left: 6%;
  }
}
.wwu__section1--mobile {
  clip-path: inset(0 0 3px 0);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 35rem;
  z-index: 3;
}
.wwu__section1--phone {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 25rem;
  z-index: 3;
}
.wwu__section1--shadow {
  position: absolute;
  top: -3rem;
  left: -23rem;
  width: 41rem;
  z-index: 2;
  opacity: 0.7;
  height: 63rem;
}
.wwu__section1--demo {
  width: 25rem;
  height: 50rem;
  z-index: 4;
  left: 50%;
  top: 4rem;
  transform: translateX(-50%);
  position: absolute;
}
.wwu__section1--ticket {
  position: absolute;
  left: -21rem;
  top: 5rem;
  width: 30rem;
  z-index: 1;
  transform: rotate(16deg);
}
.wwu__section1--vid {
  width: 22.5rem;
  position: absolute;
  z-index: 5;
  left: -11.5rem;
  top: 3rem;
  border-radius: 2rem;
  object-fit: contain;
  filter: brightness(1.15);
}
.wwu__ticket-animation--in {
  animation: pop-out 1s ease-in-out forwards;
}
.wwu__ticket-animation--out {
  animation: pop-in 1s ease-in-out forwards;
}
.wwu__section2-text {
  color: #0029FF;
}
.wwu__section2-text-box {
  width: 47rem;
  height: 100%;
  margin-right: 8rem;
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 56.25em) {
  .wwu__section2-text-box {
    width: 71rem;
  }
}
@media (max-width: 37.5em) {
  .wwu__section2-text-box {
    width: 47rem;
  }
}
.wwu__section2-text-box p {
  font-size: 2.8rem;
}
@media (max-width: 56.25em) {
  .wwu__section2-text-box p {
    font-size: 4rem;
  }
}
@media (max-width: 37.5em) {
  .wwu__section2-text-box p {
    font-size: 2.8rem;
  }
}
.wwu__section2-text-box--first {
  margin-left: 10rem;
}
@media (max-width: 37.5em) {
  .wwu__section2-text-box--first {
    margin-left: 0;
  }
}
.wwu__section2-text-box__last {
  margin-right: -100rem;
}
.wwu__section3 {
  padding: 25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.5em) {
  .wwu__section3 {
    padding: 14rem 4rem;
    flex-direction: column;
  }
}
.wwu__section3--img {
  width: 36rem;
  margin-right: 4rem;
}
@media (max-width: 37.5em) {
  .wwu__section3--img {
    margin-bottom: 4rem;
    margin-right: 0;
  }
}
.wwu__section3--text {
  max-width: 33rem;
}
.wwu__section4 {
  background-color: #F4F9FF;
  padding: 25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media (max-width: 37.5em) {
  .wwu__section4 {
    padding: 14rem 4rem 20rem;
    flex-direction: column;
  }
}
.wwu__section4--img {
  width: 31rem;
  margin-right: 10rem;
}
@media (max-width: 37.5em) {
  .wwu__section4--img {
    margin-bottom: 4rem;
    margin-right: 0;
  }
}
.wwu__section4--text {
  max-width: 34rem;
}
.wwu__section4--heading {
  max-width: 26rem;
}
.wwu__section4--bars {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 41rem;
}
.wwu__section5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25rem 22% 12rem;
}
.wwu__section5 .wwu__p {
  margin-left: -0.7rem;
}
@media (max-width: 37.5em) {
  .wwu__section5 {
    padding: 14rem 4rem 12rem 4rem;
  }
}
.wwu__section5--footer {
  width: 100%;
  margin-top: 20rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  .wwu__section5--footer {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
.wwu__section5--info {
  max-width: 33rem;
}
@media (max-width: 37.5em) {
  .wwu__section5--info {
    max-width: 22rem;
  }
}
@media (max-width: 37.5em) {
  .wwu__section5--info:not(:last-child) {
    margin-bottom: 4rem;
  }
}
.wwu__section6 {
  background-color: #F4F9FF;
  padding: 25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.5em) {
  .wwu__section6 {
    padding: 14rem 4rem;
    flex-direction: column;
  }
}
.wwu__section6--img {
  width: 36rem;
  margin-left: 8rem;
}
@media (max-width: 37.5em) {
  .wwu__section6--img {
    margin-top: 4rem;
  }
}
.wwu__section6--heading {
  max-width: 22rem;
}
.wwu__section6--text {
  max-width: 35rem;
}
.wwu__section7 {
  padding: 25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.5em) {
  .wwu__section7 {
    padding: 14rem 4rem;
    flex-direction: column-reverse;
  }
}
.wwu__section7--img {
  width: 36rem;
  margin-right: 7rem;
}
@media (max-width: 37.5em) {
  .wwu__section7--img {
    margin-top: 4rem;
    margin-right: 0;
  }
}
.wwu__section7--text {
  max-width: 35rem;
}
.wwu__section8 {
  background-color: #F4F9FF;
  padding: 25rem 15rem 8rem 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 37.5em) {
  .wwu__section8 {
    padding: 14rem 4rem 8rem;
  }
}
@media (max-width: 37.5em) {
  .wwu__section8 h1 {
    text-align: center;
  }
}
.wwu__section8--textbox {
  margin-top: 6rem;
  background-color: white;
  padding: 4rem;
  border-radius: 3rem;
  text-align: center;
}
.wwu__section8--textbox p {
  color: #1F29BA;
  line-height: 2.5rem;
}
.wwu__section8--textbox p:not(:last-child) {
  margin-bottom: 2rem;
}
.wwu__section8--textbox p span {
  font-weight: 700;
}
.wwu__section8--quote {
  font-size: 7rem;
  font-family: "trap", sans-serif;
  color: #0029FF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
  font-weight: 600;
  margin-bottom: -2rem;
}
.wwu__section8--stripe {
  margin-top: 6rem;
  color: black;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wwu__section8--stripe img {
  width: 10rem;
  margin-left: 0.5rem;
}
.wwu__h1 {
  font-size: 8rem;
  font-family: "trap", sans-serif;
  color: #0029FF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
  font-weight: 900;
  line-height: 1;
}
@media (max-width: 37.5em) {
  .wwu__h1 {
    font-size: 5rem;
    font-weight: 700;
  }
}
.wwu__h1--ko {
  font-size: 10rem;
  font-family: "trap", sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
  font-weight: 900;
  color: white;
}
@media (max-width: 37.5em) {
  .wwu__h1--ko {
    font-size: 6rem;
    font-weight: 700;
  }
}
.wwu__h1--container {
  max-width: 80rem;
  text-align: center;
}
@media (max-width: 37.5em) {
  .wwu__h1--container {
    max-width: 80%;
  }
}
.wwu__h2 {
  font-size: 2.7rem;
  font-family: "trap", sans-serif;
  color: #1F29BA;
  font-weight: 700;
}
.wwu__p {
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  font-weight: 400;
  color: #1F29BA;
  margin-bottom: 3rem;
}

.particles-container {
  position: absolute; /* Change to absolute to cover the section */
  top: 10rem;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.particle {
  position: absolute;
  bottom: 0;
  width: 15px;
  height: 15px;
  background-color: #0029FF;
  border-radius: 50%;
  transform: translateX(-50%); /* Center the particles */
  z-index: 1003;
}

.particle1 {
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 32px;
  color: #0029FF;
  border-radius: 50%;
  transform: translateX(-50%); /* Center the particles */
  z-index: 1003;
}


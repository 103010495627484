$dark_background: #E5E5E5;
.event-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
  box-sizing: border-box;
  &:hover {
    transform: scale(1.03);
  }

  &__img {
    background-color: $dark_background;
    width: 100%;
    min-height: 86%;
    height: 86%;
    object-fit: cover;
    border-radius: 25px;

  }

  &__info {
    width: 100%;
    height: 14%;
    // overflow: hidden;
    padding-top: 2.5rem;
    &>*{
      margin: 0;
      width: 100%;
      line-height: 1;
      overflow-wrap: break-word;
      overflow: hidden;  }
    &>h3 {
      margin-bottom: 4px;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 1px;

      @include responsive(phone) {
        font-size: 2.8rem;
      }
    }
    &>p {
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 1px;
      color: $color-grey-dark;

      @include responsive(phone) {
        font-size: 2.2rem;
      }
    }
    }
}

// BOOTSTRAP OVERRIDES
.modal {

    &-header {
      background-color: $color-primary;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
      border-top-left-radius: 3rem;
      border-top-right-radius: 3rem;

      
      & h5 {
        font-size: 2.8rem;
        // font: "Work Sans" ;
        font-family: $default-font-family;
        font-weight: 700;
        // text-transform: uppercase;
      }
    }
  
    &-content {
      width: 60rem;
      border: none;
      border-radius: 3rem;
      // background-color: $ch-color-grey-light;

      @include responsive(phone) {
        width: 98vw;
        // display: flex;
      }
    }


    &-body {
      max-height: 60rem;
      overflow: auto;
      background-color: white;
      border-bottom-left-radius: 3rem;
      border-bottom-right-radius: 3rem;
      @include responsive(phone) {
        max-height: none;
      }
      @include responsive(t-port) {
        max-height: none;
      }
    }


    &__close {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      font-size: 2.2rem;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      // background-color: white;
      // color: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      z-index: 100;
      font-weight: 100;

      &--black {
        color: black;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: .2s;
      }
    }
  
  }
.main__nav__modal {
  background-color: $color-primary;
  width: 100%;
  height: 100%;

  &__body {
    background-color: $color-primary;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 10;

    @include responsive(phone) {
      height: auto; // Remove height 100vh for phones
      position: relative; // Change to relative for phones
    }
  }

  &__auth {
    display: flex;
    position: absolute;
    right: 12rem;
    top: 5rem;
    width: 17rem;
    justify-content: space-between;
  }

  .modal__nav {
    background-color: $color-primary;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    .modal-content {
      @extend .modal__nav;
      margin: 0;
      position: relative;

      .modal-header {
        border-bottom: 0px;
        margin-top: 4rem;

        h5 {
          font-size: 1.5rem;
        }
        .modal__close {
          position: absolute;
          top: 4rem;
          right: 4rem;
          font-size: 3rem;
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          background-color: transparent;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.5rem;
        }
      }

      .modal-body {
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        color: #fff;
        position: absolute;
        top: 10%;
        @include responsive(phone) {
          position: relative;
        }

        .navbar__link .modal__nav__item {
          width: 100%;
          text-align: center;
          font-size: 3.5rem;
          text-decoration: none;
        }
      }
      .modal-footer {
        border-top: 0px;
        z-index: 10000;
        position: fixed;
        bottom: 0;
      }
    }
    
  }
}

.modal__nav__item {
  margin-bottom: 6rem;
}

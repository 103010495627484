.ch {


    &__logo {


        &--sm {
            width: 10rem;
        }
    }
    &__font {

        &--portrait {
            font-family: $ch-font-portrait;
            margin-bottom: -1rem;
            font-weight: 100;
        }
    }
    &-p {
        font-family: $ch-font-portrait;
        font-size: 1.6rem;
        color: $ch-color-grey-dark;
    }
    &-p1 {
        font-family: $ch-font-regular;
        font-size: 1.4rem;
        color: $ch-color-grey-dark;

        &--black {
            color: black !important;
        }

        @include responsive(t-land) {
            font-size: 1.6rem;
        }
    }

    &-p2 {
        font-family: $ch-font-regular;
        font-size: 1.2rem;
        color: $ch-color-grey-dark;
    }

    &-p3 {
        font-family: $ch-font-regular;
        font-size: 1rem;
        color: $ch-color-grey-dark;
    }
    

    &-h1 {
        font-family: $ch-font-portrait;
        font-size: 6rem;

        &--jumbo {
            font-size: 8rem;
            text-transform: uppercase;

            @media (max-width: 1451px) {
                font-size: 5.5rem;
            } 

            @include responsive(t-land) {
                font-size: 8rem;
                max-width: 70%;
            }

            @include responsive(t-port) {
                font-size: 5.5rem;
            }
        }

    }

    &-h2 {
        font-family: $ch-font-portrait;
        font-size: 3rem;

        &--light {
            color: $ch-color-grey-dark;
        }
    }

    &-h3 {
        font-size: 2.2rem;
        font-family: $ch-font-portrait;
    }

    &-h4 {
        font-size: 1.8rem;
        font-family: $ch-font-regular;
        color: $ch-color-grey-dark;
    }

    &-hr {
        width: 100%;
        height: .5rem;
        background-color: black;
        margin: 8rem 0;
    }
    

    &-btn {
        background-color: black !important;
        font-family: $ch-font-portrait !important;
        font-size: 1.6rem;
        letter-spacing: .2rem;

        &--disabled {
            background-color: $ch-color-grey-light !important;
            color: black !important;
        }
    }

    &-cart {
        border-bottom: $ch-color-grey-light 1px solid;
        width: 20rem;
        overflow-x: auto;

        &__seat {
            margin-bottom: 0 !important;
            margin-right: 2rem !important;
        }
    }

    &__checkout {
        background-color: $ch-color-grey-light;
        border-radius: 5rem;
        margin-left: 4rem;
        width: 90%;

        @media (max-width: 1451px) {

        } 
    }

    &__poster {
        width: 76rem;
        height: 47rem;
        border-radius: 2rem;
        background-size: cover !important;

        @media (max-width: 1451px) {
            width: 63rem;
            height: 39rem;
        } 

        @include responsive(t-land) {
            width: 91rem;
            height: 54rem;
        }

        @include responsive(t-port) {
            width: 40rem;
            height: 36rem;
        }

        &--container {
            @include responsive(t-land) {
                margin-right: 0;
            }
        }
    }

    &-footer {
        background-color: black !important;
        margin-top: 8rem;
        display: flex;
        flex-direction: column;
        padding: 4rem !important;
        
        &__top {
            width: 100%;
            display: flex;

        }

        &__right {
            @include responsive(t-port) {
                margin-top: 0 !important;
                width: 60%;
                & > * {
                    font-size: 1rem !important;
                }
            }
        }

        &__left {
            @include responsive(t-port) {
                display: none !important;
            }
        }

        &__divider {
            border: 1px solid white;
            width: 100%;
            margin: 4rem 0;
        }

        &__btm {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &__logo {
            width: 15rem;

            
            &--container {
                border-right: 1px $ch-color-grey-light solid;
                display: inline-block;
                padding-right: 4rem;
                margin-right: 4rem;

            }

        }

        &__st-logo {
            width: 10rem;
        }
    }

    &-overview {

        margin-right: 2rem;

        &__text {
            flex: 2;
        }
    }

    &-navbar {
        width: 100%;
        padding: 4rem 1rem;
        z-index: 100;
        // box-shadow: $shadow-light;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__logo {
            width: 75%;
            @include responsive(t-port) {
                width: 20rem;
            }
        }

        &__stripe {
            background-color: black;
            height: 2rem;

            @include responsive(t-port) {
                margin-bottom: -4rem;
            }
        }

        &__section {
            width: 33%;
            display: flex;
        }

        @include responsive(t-port) {
            padding: 1rem 1rem;
            max-height: 8rem;
        }

        &__right {
            @include responsive(t-port) {
                visibility: hidden;
                width: 30%;
            }
        }
    }

    &__hero {
        padding: 0 14rem;

        @include responsive(t-land) {
            padding: 0 7rem;
        }

        &--container {

            @include responsive(t-land) {
                display: flex;
                flex-direction: column-reverse;
            }

        }

        &-text {
           max-width: 80%; 
           @include responsive(t-land) {
            max-width: 50%;
           }
           @include responsive(t-port) {
            max-width: 100%;
           }
        }

        &--right {
            align-self: flex-start;
            @include responsive(t-land) {
                margin-bottom: 4rem;
            }
            
        }

        &--btm {
            margin-top: 3rem;
        }
    }

    &__floorplan {
        margin-left: 11rem;
        overflow: auto;
        @include responsive(t-port) {
            margin-left: 1rem;
        }
    }

    &__info {
        display: flex;

        @include responsive(t-port) {
            flex-direction: column;
        }
    }

}
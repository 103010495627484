.cart {
    display: flex;
    flex-direction: column;
    
    &__seats {
        border: solid 3px $color-primary;
        border-radius: 4px;
        height: 35rem;
        overflow-y: auto;
        padding: 1.5rem;
    }

    &__img {
        width: 20rem;
        max-height: 15rem;
    }

    &__ticket {
        padding: .5rem 1rem;
        display: flex;
        justify-content: space-between;
        border-radius: 3px;
        color: white;
        background-image: linear-gradient(to top right, $color-primary-dark, $color-primary-light);

        & span {
            font-weight: 700;
            font-size: 1.6rem;
        }

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &__total {
            
        }

    }

    &__legend {
        display: flex;
        flex-wrap: wrap;
        margin-left: 8rem;
        
        @include responsive(phone) {
            margin: 0;
        }
        
        &--item {
            padding: 1.5rem 0.5rem;
            border-radius: 10px 10px 0 0;
            width: 7rem;
            color: white;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:not(:last-child) {
                margin-right: 2rem;
            }
        }

    }



    &__remove {
        background-color: transparent;
        border: none;
        font-size: 1.6rem;
        color: white;
    }

    &__price {
        background-color: white;
        border-radius: 3rem;
        padding: 1.5rem 3rem;
        margin-top: 4rem;
    }
    
}
.tabular {
  display: flex;

  @include responsive(phone) {
    flex-direction: column;
  }
  

  &__tabs {
    display: flex;
    flex-direction: column; 
    width: 14rem;
    // background-color: #f9f9f9; // Background color for the tabs section
    margin-right: 4rem;
    
    @include responsive(phone) {
      flex-direction: row;
    }
  }

  &__tab {
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-grey-dark; 
    border-right: 1px solid #d3d3d3; 
    &:hover {
      background-color: #e0e0e0; // Hover background color
      cursor: pointer;
      transition: .2s;
    }

    &--active {
      // background-color: white; // Active tab background color
      color: black; // Active tab text color
      // border-left: 4px solid #ff7e50; // Active tab left border color
      font-weight: 700; // Active tab font weight
    }
  }

  &__link {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  &__content {
    padding: 2rem;
    flex-grow: 1; // Allow content area to grow
    box-shadow: 0px 25px 15px 0px rgba(0, 0, 0, 0.10);
    background-color: #F6F7FF;
    overflow: scroll;
  }
}
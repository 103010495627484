.admin {

  &__filters {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 4rem 2rem 2rem;
    justify-content: flex-start;

    &__section {

      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive(phone) {
        margin: 4rem 0;
      }
    }

    &__section {
      margin-right: 2rem;

      select {
        width: 27rem;
      }

      &--f3 {}
    }
  }

  &__charts {
    display: flex;
    flex-wrap: wrap;

    div {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }




}



//         // DatePicker
// .DateInput {
//   background-color: transparent;
//   width: 13rem;
//   height: 4rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @include responsive(phone) {
//       width: 10rem;
//   }
// }

// .DateInput_input {
//   background-color: transparent;

//   @include responsive(t-port) {
//     font-size: 1.4rem;
//   }

//   @include responsive(phone) {
//     font-size: 1rem;
//   }
// }

// .DateRangePicker {
//   margin-right: 2rem;
// }

// .DateRangePickerInput_arrow_svg {

//   @include responsive(phone) {
//       height: 16px;
//       width: 16px;
//   }
// }
.event-manager {

  &__banner {
    display: flex;
    margin-bottom: 2rem;

    @include responsive(phone) {
      flex-direction: column;
    }

    & span {
      margin-right: 1rem;
    }
  }

  &__poster {
    // width: 20rem;
    max-height: 20rem;
    border-radius: 2rem;
    box-shadow: 0px 3px 3px 0px rgba(39, 45, 59, 0.20);
    margin-right: 2rem;

    @include responsive(phone) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }

  &__info {

  }

  &__live {
    margin-top: 2rem;
  }

  &__tabs {
    margin: 2rem 0;
    width: 95%;

  }
  
  &__details {
    display: flex;
    width: 100%;

    &--left {
      width: 70%;
      margin-right: 4rem;
    }

    &--right {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }


    &--group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }

    &--subgroup {
      flex-grow: 1;
      margin-right: 2rem;
    }

    &--btn {
      // width: 6rem;
      // height: 4.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
      }
    }
  }

  &__publish {
    padding: 2rem 4rem;

    &__checklist {
      &-item {
        display: flex;     
        justify-content: space-between;
        align-items: center;

        p {
          color: $color-primary;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }

        span {
          color: white;

          .checklist-icon {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .yes {
            background-color: green;
          }

          .no {
            background-color: red;
          }
        }

      }
    }

  }

}
.landing__events__slider {
    .carousel {
        width: 100%;
        height: 100%;
        max-height: 63vh;
        overflow: hidden;
        .carousel-indicators li {
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }
        .carousel-inner {
            .carousel-item {
                @extend .carousel;
                // img {
                //     width: 100%;
                //     height: auto;
                // }
            }
        }
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }
}
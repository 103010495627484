.c-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 1rem;

    th, td {
        padding: 1rem 1.5rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include responsive(phone) {
            padding: 0.5rem 0.3rem;
            font-size: 1rem;
        }
    }

    th {
        color: $color-primary;
        font-weight: 500;
        font-size: 1.4rem;

        @include responsive(phone) {
            font-size: 1.2rem;
        }
    }

    thead tr:first-child th:first-child {
        border-top-left-radius: 1.5rem;
    }

    thead tr:first-child th:last-child {
        border-top-right-radius: 1.5rem;
    }

    &__row {
        width: 100%;
        margin-bottom: 2rem;
        background-color: white;

        &:not(:last-child) {
            border-bottom: 1.5px solid $color-grey-light;
        }
    }

    &__d {
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: 400;
        box-sizing: border-box;
        font-family: $default-font-family;
        color: $color-grey-light-3;
        padding: 2rem 0.5rem;

        @include responsive(phone) {
            padding: 0.5rem 0.3rem;
            font-size: 1rem;
        }

        &__email {
            display: flex;
            align-items: center;
            justify-content: center;

            @include responsive(phone) {
                justify-content: flex-start;
            }

            .icon {
                margin-left: 0.5rem;
                cursor: pointer;
            }
        }
    }

    @include responsive(phone) {
        overflow-x: auto;
        display: block;

        // th:nth-child(5), td:nth-child(5), // Hide "Company" column
        // th:nth-child(6), td:nth-child(6) { // Hide "Stripe Connect ID" column
        //     display: none;
        // }

        // thead th {
        //     &:nth-child(5), &:nth-child(6) {
        //         display: none;
        //     }
        // }
    }
}
.hz-scroll {
  position: relative;
  background-color: $color-primary-light;


  &__container {
    position: sticky;
    top: 0;
    display: flex;
    height: 100vh;
    overflow: hidden;
    align-items: center;

    @include responsive(t-port) {
      // height: 80vh;
    }
  }

  &__motion {
    display: flex;
    // margin-left: 20%;
    gap: 2rem;
  }
}
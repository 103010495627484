.wwu {

    & * {
        font-family: $alt-font-family;
    }

    &__nocursor {
        cursor: none ;
        button, a, .login__link {

            &:hover {
            cursor: none !important;
            color: $color-primary;
            }
        }
    }

    &__show-cursor {
        cursor: auto;
    }


    // &,
    // * {
    //     cursor: none;
    // }
    & button {
        
        &:hover {
            cursor: none;
            color: white;
            background-color: $color-primary;
            transition: .4s;
        }
    }

    &__section1 {

        padding: 4rem 0 40.3rem 0;
        // margin-bottom: 8rem;

        @include responsive(t-land) {
            margin-bottom: 0;
        }

        // @include responsive(phone) {
        //     margin-bottom: 30rem;
        // }

        &--content {
            text-align: center;
        }

        &--heading {

        }

        &--cta {
            margin-top: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 10;


            p {
                font-size: 3rem;
                // color: $color-grey-dark-2;
                max-width: 70%;
                line-height: 2;
                text-align: center;
                color: #e288e4;

                span {
                    // -webkit-text-stroke-width: 1px;
                    // -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
                    // color: white;
                    color: $color-primary;
                }
            }
        }

        &--imgs {
            width: 100%;
            margin-top: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &-container {
                position: relative;
                @include responsive(phone) {
                    left: 6%;
                }
            }
        }

        &--mobile {
            clip-path: inset(0 0 3px 0);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 35rem;
            z-index: 3;
        }

        &--phone {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 25rem;
            z-index: 3;
        }

        &--shadow {
            position: absolute;
            top: -3rem;
            left: -23rem;
            width: 41rem;
            z-index: 2;
            opacity: .7;
            height: 63rem;
        }

        &--demo {
            width: 25rem;
            height: 50rem;
            z-index: 4;
            left: 50%;
            top: 4rem;
            transform: translateX(-50%);
            position: absolute;
        }

        &--ticket {
            position: absolute;
            left: -21rem;
            top: 5rem;
            width: 30rem;
            z-index: 1;
            transform: rotate(16deg);
        }

        &--vid {
            width: 22.5rem;
            position: absolute;
            z-index: 5;
            left: -11.5rem;
            top: 3rem;
            border-radius: 2rem; // Adjust to match the phone's screen curvature
            // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow to mimic the bezel
            object-fit: contain; // Ensure the video covers the entire area
            filter: brightness(1.15); 
        }
    }

    &__ticket-animation {

        &--in {
            animation: pop-out 1s ease-in-out forwards;
        }

        &--out {
            animation: pop-in 1s ease-in-out forwards;
        }
    }

    &__section2 {

        &-text {
            color: $color-primary;

            &-box {
                width: 47rem;
                height: 100%;
                margin-right: 8rem;
                overflow-wrap: break-word;
                display: flex;
                justify-content: center;
                align-items: center;


                @include responsive(t-port) {
                    width: 71rem;
                }

                @include responsive(phone) {
                    width: 47rem;
                }

                & p {
                    font-size: 2.8rem;

                    @include responsive(t-port) {
                        font-size: 4rem;
                    }


                    @include responsive(phone) {
                        font-size: 2.8rem;
                    }
                }

                &--first {
                    margin-left: 10rem;

                    @include responsive(phone) {
                        margin-left: 0;
                    }
                }

                &__last {
                    margin-right: -100rem;
                }
            }
        }


    }

    &__section3 {
        padding: 25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include responsive(phone) {
            padding: 14rem 4rem;
            flex-direction: column;
        }

        &--img {
            width: 36rem;
            margin-right: 4rem;

            @include responsive(phone) {
                margin-bottom: 4rem;
                margin-right: 0;
            }
        }

        &--text {
            max-width: 33rem;
        }

    }

    &__section4 {
        background-color: $color-primary-light;
        padding: 25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include responsive(phone) {
            padding: 14rem 4rem 20rem;
            flex-direction: column;
        }

        &--img {
            width: 31rem;
            margin-right: 10rem;

            @include responsive(phone) {
                margin-bottom: 4rem;
                margin-right: 0;   
            }
        }

        &--text {
            max-width: 34rem;
        }

        &--heading {
            max-width: 26rem;
        }

        &--bars {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 41rem;
        }
    }

    &__section5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25rem 22% 12rem;

        .wwu__p {
            margin-left: -.7rem;
        }
        
        @include responsive(phone) {
            padding: 14rem 4rem 12rem 4rem;
        }

        &--footer {
            width: 100%;
            margin-top: 20rem;
            display: flex;
            justify-content: space-between;
            @include responsive(phone) {
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

            }
        }
        
        &--info {
            max-width: 33rem;
            
            @include responsive(phone) {
                max-width: 22rem;
            }
            
            &:not(:last-child) {
                @include responsive(phone) {
                    margin-bottom: 4rem;
                }
            }
        }

    }

    &__section6 {
        background-color: $color-primary-light;
        padding: 25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include responsive(phone) {
            padding: 14rem 4rem;
            flex-direction: column;
        }

        &--img {
            width: 36rem;
            margin-left: 8rem;

            @include responsive(phone) {
                margin-top: 4rem;
            }
        }

        &--heading {
            max-width: 22rem;
        }

        &--text {
            max-width: 35rem;
        }

    }

    &__section7 {
        padding: 25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @include responsive(phone) {
            padding: 14rem 4rem;
            flex-direction: column-reverse;
        }
        &--img {
            width: 36rem;
            margin-right: 7rem;

            @include responsive(phone) {
                margin-top: 4rem;
                margin-right: 0;
            }
        }

        &--text {
            max-width: 35rem;
        }
    }

    &__section8 {
        background-color: $color-primary-light;
        padding: 25rem 15rem 8rem 15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include responsive(phone) {
            padding: 14rem 4rem 8rem;
        }

        h1 {
            @include responsive(phone) {
                text-align: center;
            }
        }

        &--textbox {
            margin-top: 6rem;
            background-color: white;
            padding: 4rem;
            border-radius: 3rem;
            text-align: center;

            & p {
                color: $color-secondary;
                line-height: 2.5rem;

                &:not(:last-child) {
                    margin-bottom: 2rem;
                }

                & span {
                    font-weight: 700;
                }
            }
        }

        &--quote {
            font-size: 7rem;
            font-family: $alt-font-family;
            color: $color-primary;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
            font-weight: 600;
            margin-bottom: -2rem;
        }

        &--stripe {
            margin-top: 6rem;
            color: black;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                width: 10rem;
                margin-left: .5rem;
            }
        }
    }

    &__h1 {
        font-size: 8rem;
        font-family: $alt-font-family;
        color: $color-primary;
        // color: black;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
        font-weight: 900;
        line-height: 1;

        @include responsive(phone) {
            font-size: 5rem;
            font-weight: 700;
        }


        &--ko {
            font-size: 10rem;
            font-family: $alt-font-family;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--sample-blue-2, #0029FF);
            font-weight: 900;
            color: white;

            @include responsive(phone) {
                font-size: 6rem;
                font-weight: 700;
            }
            
        }

        &--hover {
            // mix-blend-mode:difference;
            // background-color: $color-primary;
        }

        &--container {
            // height: 30rem;
            max-width: 80rem;
            text-align: center;

            @include responsive(phone) {
                // height: 40rem;
                max-width: 80%;
            }
        }
    }

    &__h2 {
        font-size: 2.7rem;
        font-family: $alt-font-family;
        color: $color-secondary;
        font-weight: 700;
    }

    &__p {
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3rem;
        font-weight: 400;
        color: $color-secondary;
        margin-bottom: 3rem;
    }

}

.particles-container {
    position: absolute; /* Change to absolute to cover the section */
    top: 10rem;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
  
.particle {
    position: absolute;
    bottom: 0;
    width: 15px;
    height: 15px;
    background-color: $color-primary;
    border-radius: 50%;
    transform: translateX(-50%); /* Center the particles */
    z-index: 1003;
}

.particle1 {
    position: absolute;
    bottom: 0;
    width: 32px;
    height: 32px;
    // background-color: $color-primary;
    color: $color-primary;
    // background-color: green;
    border-radius: 50%;
    transform: translateX(-50%); /* Center the particles */
    z-index: 1003;
}
body {
  font-family: sans-serif;
  font-weight: 400px;
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.heading {

    &__1 {
      font-size: 5rem;
      font-weight: 900;
      font-family: $default-font-family;

      &--ko {
        color: white;
      }
    }

    &__2 {
      font-size: 4.5rem;
      font-weight: 700;
      font-family: $default-font-family;

      &--ko {
        color: white;
        font-size: 3.5rem;
        font-weight: 700;
        font-family: $default-font-family;
      }
    }

    &__3 {
      font-size: 4rem;
      font-weight: 700;
      font-family: $default-font-family;
    }
    
    &__4 {
      font-size: 3.5rem;
      font-weight: 700;
      font-family: $default-font-family;
      
      &--ko {
        color: white;
      }
    }

    &__5 {
      color: black;
      font-size: 2rem;
      font-weight: 700;
      font-family: $default-font-family;

    }
}

label {
  font-size: 1.6rem;
  font-weight: 900;
  font-family: $default-font-family;

}

p {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: $default-font-family;
  line-height: 1.3;
}

.p {
  &__1 {
      font-size: 1.8rem;
      font-weight: 500;
      font-family: $default-font-family;
      &--ko {
          font-size: 1.8rem;
          font-weight: 500;
          font-family: $default-font-family;
          color: white;
      }
  }
  &__2 {
      font-size: 1.6rem;
      font-weight: 500;
      font-family: $default-font-family;
      line-height: 1.3;
      &--ko {
          color: white;
      }
  }   

}
.subtext {
  color: $color-grey-dark;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: $default-font-family;
  line-height: 1;
  &--ko {
      color: $color-grey-light;
  }

  &--red {
    color: red;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: $default-font-family;
  }
}


.text {

  &__main {
    font-size: 1.6rem;
    
    &--ko {
      color: white;
      font-size: 1.6rem;
    }
  }


}

.link {

  &--visited {

    &:visited {
      color: purple;
    }
  }

  &--sm {
    color: $color-primary;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

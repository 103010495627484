.discount {
  display: flex;  
  justify-content: space-between; 
  align-items: center;

  &__btn {
    width: 8rem;
  }

  &__input {
    width: 75%;
    margin-right: 2rem;

    input {
      margin-bottom: 0;
    }
  }
}
.select {
  font-family: $default-font-family;
  font-size: 1.6rem;
  font-weight: 400;
  @include responsive(phone) {
    width: 100%;
    display:flex;
    justify-content: space-between;
  }
}

select {
  padding: 1rem 2rem;
  font-family: $default-font-family;
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
  
  &:hover {
    cursor: pointer;
  }
}


option {
  font-family: $default-font-family
} 

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  border: solid black;
  border-width: 0 1px 1px 0;
  padding: 2.5px;
  transform: rotate(45deg);
  margin-left: 1.5rem;
  vertical-align: middle;

  &.asc {
    transform: rotate(-135deg);
  }
  
  &.desc {
    transform: rotate(45deg);
  }
  
  &.hidden {
    visibility: hidden;
  }
}


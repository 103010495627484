// CSS variables
:root {
    //COLORS
    --color-primary: #0029FF;
    --color-primary-dark: #310014;
    --color-primary-light: #B04671;

    --color-secondary: #558C8C;
    --color-secondary-dark: #1A5C5C;
    --color-secondary-light: #B9D4D4;


    --color-slate: #535558;
    --color-success: #4cd964;
    --color-error: rgb(214,47,26);
    --color-caution:#FFCC00;
    --color-handicap: #003d83;


    --color-grey-dark: #707070;;
    --color-grey-light: #C6C6C6;;

    // FONT
    --default-font-size: 1.6rem;
    --default-font-family: 'Montserrat', sans-serif;

    // CHORUS HALL VARS
    // CH COLORS
    --ch-color-white: #ffffff;
    --ch-color-offwhite: #dbdfdb;
    --ch-color-black: #1e1c1a;
    --ch-color-orange: #a9753b;
    --ch-color-grey: #3e4642;
  
    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);
  
    --line: 1px solid var(--grey-light-2);

    // --slicktix-gradiant-1: linear-gradient(90deg, #9BC9FF 0%, #D59EEF 50%, #FF7FE3 100%);
}

//COLORS
$color-primary:  #0029FF;
$color-primary-dark: #310014;
$color-primary-light: #F4F9FF;

$color-secondary: #1F29BA;
$color-secondary-dark: #1A5C5C;
$color-secondary-light: #B9D4D4;

$color-action: #e37c6d;

$color-slate: #535558;
$color-success: #4cd964;
$color-error: rgb(214,47,26);
$color-caution:#FFCC00;
$color-handicap: #003d83;


$color-grey-dark: #8a8787;
$color-grey-dark-2: #929292;
// $color-grey-light: #f7f7f7;
$color-grey-light: #d5d5d5;
$color-grey-light-2: #A2A2A2;
$color-grey-light-3: #8A8A8A;
$color-grey-light-4: #F6F7FF;

$color-btn-alt: linear-gradient(270deg, #FF978E 105.51%, #FFB784 -51.89%);


$color-green-light: rgba(138, 211, 149, 0.41);
$color-green-dark:#7CB484;

$color-blue-light: #CBE9FF;
$color-blue-dark:#0097FF;;


@font-face {
  font-family: 'basis-grotesque-regular';
  src: url('./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.eot');
  src: url('./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.woff2') format('woff2'),
       url('./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.woff') format('woff'),
       url('./assets/fonts/basis-grotesque-regular/basis-grotesque-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis-grotesque-medium';
  src: url('./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.eot');
  src: url('./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.woff2') format('woff2'),
       url('./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.woff') format('woff'),
       url('./assets/fonts/basis-grotesque-medium/basis-grotesque-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis-grotesque-italic';
  src: url('./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.eot');
  src: url('./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.woff2') format('woff2'),
       url('./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.woff') format('woff'),
       url('./assets/fonts/basis-grotesque-italics/basis-grotesque-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'portrait-light';
  src: url('./assets/fonts/Portrait-Light.otf');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'work-sans';
//   src: url('./assets/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'TrapFont';
  src: url('./assets/fonts/Its-not-a-Trap/Trap-Light.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-Regular.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-Black.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-Bold.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-ExtraBold.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-Medium.otf') format('opentype'),
        url('./assets/fonts/Its-not-a-Trap/Trap-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-Light.otf') format('opentype');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-Regular.otf') format('opentype');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-Medium.otf') format('opentype');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-SemiBold.otf') format('opentype');
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-Bold.otf') format('opentype');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-ExtraBold.otf') format('opentype');
  font-weight: 800; /* ExtraBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'trap';
  src: url('./assets/fonts/trap/Trap-Black.otf') format('opentype');
  font-weight: 900; /* Black weight */
  font-style: normal;
}
$shadow-light: 0 .6rem 1rem rgba(black, .7);


// FONT
$default-font-size: 1.6rem;
$default-font-family: 'Montserrat', sans-serif;
$alt-font-family: 'trap', sans-serif;

// CHORUS HALL VARS
// CH COLORS
$ch-color-white: #ffffff;
$ch-color-offwhite: #dbdfdb;
$ch-color-black: #1e1c1a;
$ch-color-orange: #a9753b;
$ch-color-grey: #3e4642;

// PALACE OF FINE ARTS
$palace-color-primary: #750D37;

// SCREEN SIZE
$bp-largest: 75em; //1200px
$bp-large: 68.75em; //1100px
$bp-medium: 56.25em; //900px
$bp-small: 37.5em; //600px
$bp-smallest: 31.25em; //500px


// CHORUS HALL
$ch-color-gold: #AB773E;
$ch-color-grey-light: #EDEDED;
$ch-color-grey-dark: #707070;
$ch-font-regular: 'basis-grotesque-regular';
$ch-font-medium: 'basis-grotesque-medium';
$ch-font-italic: 'basis-grotesque-italic';
$ch-font-portrait: 'portrait-light';

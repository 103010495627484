.tts {
    display: flex;
    justify-content: space-between;
    font-family: "Work Sans", sans-serif;
    font-size: 1.6rem;

    &__desc {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
    }

    &__type {
        font-weight: 700;
    }

    &__category {
        font-weight: 700;
        font-family: "Work Sans", sans-serif;
        font-size: 1.6rem;
    }

    &__price {

    }

    &__subcat {
        display: flex;
        justify-content: space-between;

        > *:not(:last-child) {
            margin-bottom: 2rem;
        }

        &-container {
            display: flex;
            flex-direction: column;
        }
    }

    &__btns {
        display: flex;
        align-items: center;

        > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__btn {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        border: none;
        background-color: $color-primary;

        &:hover {
            cursor: pointer;
        }

        &--inactive {
            background-color: $color-grey-dark;
            &:hover {
                cursor: default;
            }

            &:active {
                outline: none;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &__num-selected {

    }

    &__totals {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
    }
}
.create-event {
  margin: 2rem;
  padding: 4rem;
  background-color: rgba(244, 244, 244, 0.62);
  border-radius: 2rem;

  &__heading {
    font-size: 3rem;
    font-family: $default-font-family;
    margin-bottom: 2rem;
  }

  &__1 {
    display: flex;
    justify-content: space-between;

    &--left {
      width: 75%;
      margin-right: 4rem;
    }

    &--right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    &--img-upload {
      display: flex;
    }
  }

}
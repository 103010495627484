.alert-toast {
    background-color: $color-primary !important;
    // background-color: $tans-black !important;
    color: white !important;
    font-weight: 900 !important;
    font-size: 1.6rem !important;
    padding: 1rem 2rem 2rem 2rem !important;

    &__success {
        background: $color-success !important;
    }

    &__error {
        background: $color-error !important;
    }

    &__warning {
        background: $color-secondary !important;
    }
}

.Toastify__close-button--light {
    color: white !important;
    opacity: .7;
}
.theater {
    max-width: 100vw;
    overflow: hidden;

    &__nav {
        position: relative;
    }

    &__hero {
        position: relative;
        // padding: 4vh 17%;
        margin-bottom: 10rem;
        width: 100vw;
        height: 60vh;


        @include responsive(phone) {
            margin-bottom: 4rem;
        }

        // @media (max-width: 1451px) {
        //     padding: 10rem 17%;
        // } 

        // @include responsive(t-land) {
        //     padding: 4rem 14rem;
        // }

        &--bg {
            filter: blur(40px);
            opacity: .4;
            width: 100%;
            height: 100%;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        &--icon {
            width: 1.5rem;
            margin-right: .5rem;
        }


        // @include responsive(t-land) {
        //     padding: 0 7rem;
        // }

        @include responsive(phone) {
            padding: 0 1rem;
        }

        &--container {
            display: flex;
            width: 100vw;
            justify-content: center;
            align-items: center;

            // @include responsive(t-land) {
            //     display: flex;
            //     flex-direction: column-reverse;
            // }

            @include responsive(phone) {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }

        }

        &-heading {
            // margin-top: 4rem;
            text-align: left;
            line-height: 1.3;
            font-weight: 700;

            @include responsive(phone) {
                font-size: 4rem;
                margin-bottom: 1rem;
            }
        }

        &-text {
            margin-top: 1rem;
            margin-bottom: 2rem;
            text-align: left;
            line-height: 1.5;
        }

        &--left {
            align-self: flex-start;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            // padding-top: 8rem;
            height: 100%;
            
            // width: 55rem;
            // flex: 1;

            // @include responsive(t-land) {
            //     margin-bottom: 4rem;
            //     width: 100%;
            // }

            @include responsive(phone) {
                width: 100%;
                text-align: center;
                margin-bottom: 2rem;
                padding: 4rem 2rem 0;
                margin-right: 0;

            }

        }


        &--btn {
            width: 80%;
            padding: 1rem 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &-container {
                display: flex;
                justify-content: center;
            }
        }

        &--btm {
            margin-top: 3rem;
        }
    }

    &__buy-tix {
        margin-top: 4rem;

        @include responsive(phone) {
            margin-top: 2rem;
        }
    }

    &__btn {
        padding: 1.5rem 3rem;
        font-weight: 500;
    }

    &__poster {
        // width: 45rem;
        max-width: 50rem;
        max-height: 50rem;
        border-radius: 3rem;
        margin-top: auto; /* Let margin grow to center the element */
        margin-bottom: auto; /* Center the element vertically */
        margin-right: 6rem;



        @media (max-width: 1451px) {
            max-width: 50rem;
            max-height: 50rem;
        }

        @include responsive(phone) {
            margin-top: 0;
        }



        &--container {
            height: 100%;
            display: contents;

            @include responsive(phone) {
                margin-right: 0;
            }
        }
    }

    &__logo {


        &--sm {
            width: 10rem;
            margin-top: -1rem;
        }
    }


    &__overview {

        margin-right: 8rem;

        @include responsive(phone) {
            margin-right: 0;
        }

        &__text {
            flex: 2;
        }
    }

    &__info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 4rem;

        @include responsive(t-port) {
            flex-direction: column;
        }
    }

    &__cart {
        border-bottom: $ch-color-grey-light 1px solid;
        width: 20rem;
        overflow-x: auto;

        &__seat {
            margin-bottom: 0 !important;
            margin-right: 2rem !important;
        }

        &__btn {
            // padding: 1.5rem 10rem;
            padding: 1.5rem 0;
            width: 100%;
        }
    }

    &__floorplan {

        &-container {
            overflow-x: scroll;
            padding: 0 8rem;


            @include responsive(phone) {
                padding: 0 1rem;
            }
        }
    }
}
.ticker {
  background-color: black;
  color: white;
  padding: 1rem 0;
  height: 5rem;
  width: 100%;
  white-space: nowrap;
  font-size: 1.6rem;
  font-family: 'Work Sans';
  font-weight: 700;
  position: relative;
  overflow: hidden;

  &-text {
      width: 100%;
      position: absolute;
      animation: scroll 12s linear infinite;
      max-width: 100vw;

      & span {
      }
  }

}
.showrunner {
    padding: 4rem 0 4rem 4rem;
    overflow: hidden;
    background-color: #F6F7FF;
    min-height: 80vh;

    @include responsive(phone) {
        padding: 0 2rem;
    }

    &__floorplan {
        overflow: auto;
        margin-top: 2rem;

        &__selected {}
    }

    &__page {
        display: flex;
        // flex-direction: column;
    }

    &__container {
        width: 100%;
        overflow: hidden;
    }

    &__prods {
        display: flex;
        flex-wrap: wrap;

        @include responsive(phone) {
            flex-direction: column;
            align-items: center;
        }

        &__separator {
            width: 90%;
            height: 1px;
            background-color: $color-grey-dark;
            margin: 2rem 0 4rem 0;
        }
    }

    &__finances {
        // width: 95%;

        .DateInput {
            background-color: transparent;
            // width: 13rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
          
            @include responsive(phone) {
                // width: 10rem;
            }
          }
          
          .DateInput_input {
            background-color: transparent;
          
            @include responsive(t-port) {
            //   font-size: 1.4rem;
            }
          
            @include responsive(phone) {
            //   font-size: 1rem;
            }
          }
    }

    &__orders {
        // max-width: 90vw;
        width: 95%;
        height: 100%;
        // overflow: hidden;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include responsive(phone) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        .DateInput {

        
            @include responsive(phone) {
              margin-bottom: 2rem !important;
            }
          }
    }

    &__ticket-manager {
        box-shadow: $shadow-light;
        background-color: black;
    }

    &__filters {
        display: flex;
        // justify-content: space-around;
        align-items: center;
        padding: 4rem 0;
        width: 100%;

        @include responsive(phone) {
            flex-direction: column;
            align-items: flex-start;
        }

        div {
            @include responsive(phone) {
                // margin-bottom: 2rem;
            }
        }



        &__section {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            select {
                width: 27rem;
            }

            .select {
                width: 27rem;
            }

            &--f1 {
                @include responsive(phone) {
                    width: 100%;
                }
            }

            &--f2 {

                @include responsive(phone) {
                    width: 88.5%;

                }

                span {
                    @include responsive(phone) {
                        // margin-left: 80%;
                        display: block;
                    }
                }

                // span.caret {
                //     margin-right: 2rem;
                // }
            }

        }

        &--event {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 2rem;

            select {
                max-width: 30rem;
            }
        }

        &--time {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 2rem;
            width: 37rem;
        }

        &--reset {
            background-color: transparent;
            border: none;
            color: $color-grey-dark;
        }

        &--search {
            // width: 40%;
            margin-right: 2rem;
            flex-grow: 1;

            input {
                margin-bottom: 0;
                font-family: $default-font-family;
                color: $color-grey-light-3;
                font-size: 1.2rem;
            }
        }
    }

    &__refund {

        &__btn {
            border: none;
        }
    }

    select {

        &:hover {
            cursor: pointer;
        }
    }


}
.seat {

    // height: 2rem;
    // width: 3rem;
    // margin-right: 5px;
    height: .8rem;
    width: 1rem;
    margin-right: 5px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;

    // @media screen and (max-width: 766px){
    //     height: .6rem;
    //     width: .8rem;
    // }

    &--wc {
        width: 1.8rem;
    }

    &--selected {
        width: 1.8rem;
        // color: $color-primary;
        // background-color: $color-primary;
        fill: $color-primary;
    }


    &:hover {
        cursor: pointer;
        opacity: .8;
        transition: .2s;
    }

    &--sm {
        height: 1rem;
        width: 2.2rem;
        margin-right: 2px;
        margin-bottom: 1rem;
        color: $color-primary;

        &:hover {
            cursor: pointer;
        }
    }


    &--blank {
        visibility: hidden;

        &:hover {
            cursor: default;
        }
    }

    &--taken {
        // color: $color-slate;
        color: $ch-color-grey-dark;

        &:hover {
            cursor: default !important;
        }
    }


    &__stage {
        background-color: $ch-color-grey-light;
        font-family: $ch-font-portrait;
        text-transform: uppercase;
        letter-spacing: .1rem;
        font-size: 2rem;
        opacity: .8;
        font-weight: 700;
        width: 40%;
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        border-radius: 10rem;

        @media (max-width: 600px) {
            width: 50rem !important;
        }

    }

    &-st {

        &__color {
            &--available {
                background-color: $ch-color-gold;
            }

            &--unavailable {
                background-color: $ch-color-grey-light !important;
            }

            &--ada {
                background-color: black;
            }
        }
    }

    &-ch {

        &__color {


            &--available {
                background-color: $ch-color-gold;
            }

            &--unavailable {
                background-color: $ch-color-grey-light !important;

                &:hover {
                    cursor: default !important;
                }
            }

            &--ada {
                background-color: black;
            }

            &-selected {
                margin-right: 5px;
                padding: 0 3px;
                margin-bottom: 1rem;

                @include responsive(t-port) {
                    margin-right: 0;
                    padding: 0 1px;
                }

                &>* {
                    @include responsive(t-port) {

                        height: 1.5rem;
                        width: 1.5rem;

                    }
                }

                &:hover {
                    cursor: pointer;
                    transition: .2s;
                    transform: scale(1.05);
                    opacity: .8;
                }

                &--available {
                    fill: $ch-color-gold;

                }

                &--ada {
                    fill: black;
                }
            }

        }
    }
    
    &-palace {

        &__color {


            &--available {
                background-color: $palace-color-primary;
            }

            &--unavailable {
                background-color: $ch-color-grey-light !important;

                &:hover {
                    cursor: default !important;
                }
            }

            &--ada {
                background-color: black;
            }

            &--wc {
                background-color: #0B622E;
            }

            &-selected {
                margin-right: 2px;
                padding: 0 1px;
                
                @include responsive(t-port) {
                    margin-right: 0;
                    padding: 0 1px;
                }
                
                &>* {
                    height: 1.5rem;
                    width: 1.5rem;

                }

                &:hover {
                    cursor: pointer;
                    transition: .2s;
                    transform: scale(1.05);
                    opacity: .8;
                }

                &--available {
                    fill: $palace-color-primary;

                }

                &--ada {
                    fill: black;
                }
            }

        }

    }

}
.seats {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    padding: 5rem;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;

    

    &__hero {
      height: 30rem;
      padding: 5rem;
      width: 100%;
      background-image: linear-gradient(to right top, rgba($color-primary-light, 0.9), 
      rgba($color-primary-dark, 0.9));
      display: flex;

      &--img {
        height: 100%;
        border-radius: 5px;
        opacity: .9;
      }


    }

    &__confirm {
      background-color: rgba(black, .85);
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem auto;
    }

    &__dude {
      margin-top: 5rem;
      width: 40rem;
      height: 40rem;
      object-fit: cover;
      border-radius: 50%;
    }

    &__farewell {
      font-family: 'Sriracha', cursive;
      color: white;
      font-size: 4rem;
      margin-top: 4rem;
      width: 70%;
    }

    &__options {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__selection {
      width: auto;
      margin: auto;
      max-width: 70%;
      overflow: scroll;

      @media (max-width: 960px) {
        max-width: 90%;
      }
    }

    &__checkout {

        &-button {
            border: none;
            padding: 1rem;
            border-radius: 5px;
            background-color: $color-primary;
            font-size: 1.6rem;
            font-weight: 700;
            color: white;
          
            &:active {
                transform: translateY(2px);
                transition: .2s;
              }
            
              &:hover {
                cursor: pointer;
                transform: translateY(-2px);
                transition: .2s;
              }
        }
    }

    &__seats {
      padding: 2rem;
    }

    &__cart {
      width: auto;
      margin: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

    }
}

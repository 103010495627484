@mixin clearfix {
  &::after{
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//MEDIA QUERY MANAGER
/*
0 - 600px: Phone

600px - 900px: Tablet-Portrait

900px - 1200 px: Tablet-Landscape

[1200px - 1800px]: Normal styling

1800px + : Large desktop

Good working order: Base & Typography -> General Layout & Grid -> Page Layout -> Components

*/

@mixin responsive-phone {
  @media (max-width: 600px) { @content };
}



@mixin responsive($breakpoint) {
  @if $breakpoint == phone-sm {
    //em number calculated by dividing the amount of px by default font size (16px) 
    @media (max-width: 28.5em) { @content }; //450px 
  }

  @if $breakpoint == phone {
    //em number calculated by dividing the amount of px by default font size (16px) 
    @media (max-width: 37.5em) { @content }; //600px 
  }

  @if $breakpoint == t-port {
    @media (max-width: 56.25em) { @content }; //900px
  }

  @if $breakpoint == t-land {
    @media (max-width: 75em) { @content }; //1200px
  }

  @if $breakpoint == l-desk {
    @media (min-width: 112.5em) { @content }; //1800px
  }
}

.show-banner {
    background-size: cover;
    width: 100vw;
    height: 95rem;
    position: relative;

    @include responsive(phone) {
        padding: 0 4rem;
    }

    &__overlay {
        background-color: rgba(#5a5a5a, .7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__heading {
        color: white;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        text-transform: uppercase;
        font-size: 8rem;
        position: relative;
        z-index: 10;
        top: 10rem;
        font-weight: 700;
        max-width: 50%;
        margin: 0 auto;
        line-height: 1.2;
        margin-bottom: 2rem;

        @include responsive(phone) {
            font-size: 6rem;
            max-width: 95%;
        }
    }

    &__desc {
        font-size: 2rem;
        color: white;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        position: relative;
        z-index: 10;
        top: 10rem;
        max-width: 70%;
        margin: 0 auto;
        margin-bottom: 4rem;

        @include responsive(phone) {
            font-size: 1.6rem;
            max-width: 85%;
        }
    }

    &__btn {
        background-color: $color-action;
        padding: 2rem 18rem;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Work Sans', sans-serif;

        &:hover {
            background-color: #9ccde2;
            transition: .2s;
        }

        @include responsive(phone) {
            padding: 2rem 14rem;

        }

        &--container {
            position: relative;
            z-index: 10;
            top: 10rem;
            display: flex;
            justify-content: center;
        }
    }

    &__date {
        background-color: $color-grey-light;
        color: black;
        border-radius: 0;
        width: 100%;
        font-size: 2rem;
        &:hover {
            cursor: pointer;
            background-color: $color-grey-light-2;
            transition: .2s;
            text-decoration: none !important;
        }

        & * {
            &:hover {
                text-decoration: none !important;
            }
        }
    }


}
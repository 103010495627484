.dash-home {

  &__heading {
    font-size: 4rem;
    font-weight: 900;
  }

  &__financial {
    margin-top: 4rem;
  }

  &__stripe-sign-up {
    margin-top: 4rem;
  } 

  &__charts {
    display: flex;
    padding-bottom: 2rem;
    width: 100%;
    justify-content: space-around;

    @include responsive(phone) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__chart {
    height: 37rem;
    width: 45rem;
    background-color: white;
    border-radius: 3rem;
    padding: 3rem 3rem 6rem;

    @include responsive(phone) {
      width: 100%;
      margin-bottom: 4rem;
      margin-right: 0;
    }

    @include responsive(t-land) {
      margin-right: 2rem;
    }

    &__title {
      color: $color-grey-light-2;
    }

    &__total {
      font-weight: 700;
    }
  }

  &__orders {
    padding-right: 2rem;
    margin-top: 4rem;

    @include responsive(phone) {
      overflow-x: scroll;
      padding-right: 0;
    }
  }
}

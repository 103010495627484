.footer {
    box-sizing: border-box;
    background-color: transparent;
    padding: 4rem 4rem;
    font-size: 1.4rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;

    &__sm_flex_col {
      @media (max-width: 680px) {
          flex-direction: column;
        }
    }
    &__right {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      width: 70%;
      justify-content: space-evenly;

      & > * {

        @include responsive(phone) {
          margin: 0 1rem;
        }
      }

      @media (max-width: 680px) {
        width: 100%;
        margin-top: 0;
        // justify-content: space-between;
      }

    }
      &__logo-box {
        text-align: center;
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background: transparent;
        width: 25%;
  
        @media (max-width: 680px) {
          width: fit-content;
          justify-content: center;
        }
      }
  
      &__logo {
        width: min(20rem, 100%);
        height: auto;

        @include responsive(phone) {
         margin-bottom: 3rem;
        }
      }
    &__white{
      color: #fff;
      justify-content: space-between;
      &>.footer__logo-box {
        @media (max-width: 680px) {
            width: 30%;
          }
      }
      &>.footer__right {      
          @media (max-width: 680px) {
            width: 55%;
          }
          &>.sm_d_none {
            @media (max-width: 680px) {
              display: none;
            }
          }
        }
    }
    
  }
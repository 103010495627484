.hover {
  position: relative;
  overflow: hidden;

  &__cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 41, 255, 0.5);
    pointer-events: none;
    z-index: 9999;

    &--hover {
      background-color: rgba(255, 0, 0, 0.5); /* Change color on hover */
      // transform: scale(2.5); /* Increase size on hover */
    }

    // @include responsive(phone) {
    //   display: none;
    // }
  }

  &__shapes {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100vh;
    width: 10rem;
    height: 10rem;
    pointer-events: none;
    // opacity: .3;

    &__shape {
      position: absolute;
      will-change: transform;
      -webkit-will-change: transform;
      -moz-will-change: transform;
      -ms-will-change: transform;
      -o-will-change: transform;
      border-radius: 50%;
      z-index: 10000;
      opacity: 0;
      overflow: hidden;
    }

    @include responsive(phone) {
      display: none;
    }
  }

  &__content {
    position: relative;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    mix-blend-mode: screen;
    -webkit-mix-blend-mode: screen;
    -moz-mix-blend-mode: screen;
    -ms-mix-blend-mode: screen;
    -o-mix-blend-mode: screen;
    background: white;
    overflow: hidden;

    &--mobile {
      position: relative;
      z-index: 1001;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

  }
}



.reports {

    &__production {
        display: flex;
    }
    
    &__shows {

        &-tabs {
            display: flex;
        }
    }

    &__statbox {
        border: $color-primary-dark solid 3px;
        border-radius: 4px;
        width: 10rem;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
        color: $color-primary;

        &--sm {
            border: $color-primary-dark solid 3px;
            border-radius: 4px;
            width: 7.5rem;
            height: 7.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.75rem;
            font-weight: 700;
            color: $color-primary;
    
        }
    }

    &__scrollbox {
        max-height: 50rem;
        overflow: auto;
        border-radius: 2px solid $color-primary;
    }


}
.edit-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__input {
      max-width: 80%;
      margin-right: 1rem;
    }
    
    &__text {
      font-family: $default-font-family;
      font-size: 1.8rem;
  
      &--ko {
        color: white;
      }
    }

    &__edit {
        font-size: 1.6rem;
        
        &:hover {
            cursor: pointer;
        }
    }
  }
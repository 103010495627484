.launch-event {
  margin: 2rem auto;
  padding: 4rem;
  background-color: rgba(240, 240, 240, 0.3);
  border: 1px solid $color-primary;
  border-radius: 2rem;
  max-width: 70vw;

  @include responsive(phone) {
    max-width: 95%;
  }

  &__heading {
    font-size: 3rem;
    font-family: $alt-font-family;
    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    justify-content: space-between;

    @include responsive(phone) {
      flex-direction: column;
    }

    &--left {
      width: 75%;
      margin-right: 4rem;

      @include responsive(phone) {
        width: 100%;
        margin-right: 0;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

  }
  
  &__img-upload {
    display: flex;

    @include responsive(phone) {
      margin-bottom: 4rem;
      margin-top: 2rem;
      width: 100%;
    }
  }

  &__submit {

  }

  .image-upload__sublabel {
    @include responsive(phone) {
      text-align: center;
    }
  }

  textarea.input {
    width: 100%;
    height: 100px;
  }

  &__private {

    &__group {
      display: flex;
      align-items: center;

      label
       {
        margin-bottom: 0;
      }
    }
  }

  &__custom-features {
    min-width: 90%;
    max-width: 110%;
  }
}


.financial {


  &__wave {
    width: 100%;
    height: 50rem;
    flex-grow: 1;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // z-index: -1;
  }

  
  &__stripe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &__logos {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 5rem;
      width: 43%;
    }

    &__separator {
      width: 3px;
      height: 12rem;
      background-color: $color-grey-dark;
    }
    &__logo {
      width: 20rem;

      &--pad {
        padding: 0 1.5rem;
        margin-bottom: -.5rem;
      }
    }
  }

  &__filters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4rem 0;
    // border-top: 1px solid $color-grey-dark;
    // border-bottom: 1px solid $color-grey-dark;

    &--event {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--time {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 40%;
    }

    &--reset {
     background-color: transparent;
     border: none;
     color: $color-grey-dark; 
     margin-left: 2rem;
    }
  }

}
.event {

  &__preview {
    width: 100%;
    background: var(--slicktix-gradiant-1, linear-gradient(90deg, #9BC9FF 0%, #D59EEF 55%, #FF7FE3 100%));
    padding: 1rem 0;
    text-align: center;
    color: white;
    font-family: $default-font-family;
    font-size: 3rem;
  }

  &__hero {
    height: 60vh;
    display: flex;
    // padding: 4rem 0;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;

    @include responsive(phone) {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
    }

    &__bg {
      filter: blur(40px);
      opacity: .4;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &__poster {
      max-width: 40rem;
      max-height: 50rem;
      border-radius: 1rem;
      // height: 38rem;
      // width: 33rem;
      // background-size: cover;
      // background-position: center;
      box-shadow: 0px 8px 11px rgba(0, 0, 0, .25);
      margin-right: 8rem;

      @include responsive(phone) {
        margin-right: 0;
        margin-bottom: 4rem;
        align-self: center;
      }
    }

    &__content {
      position: relative;
      z-index: 1;
      max-width: 40%;

      @include responsive(phone) {
        max-width: 90%;
        margin: 0 auto;
        text-align: center;
      }
    }

    h3 {
      font-size: 5.6rem;
      font-family: trap;
      // color: $color-primary;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 1.4rem;
    }

    p {
      margin-bottom: 1.4rem;
      font-family: $default-font-family;

      svg {
        width: 1.2rem;
        margin-right: 1rem;
        fill: #3e4642;
      }
    }

    button {
      margin-top: 2rem;
      color: #F1F1F1;
      font-weight: 500;
      padding: 1.5rem 3rem;
      box-shadow: 0px 8px 11px rgba(0, 0, 0, .25);
    }

  }

  &__select {
    width: 100%;
    // margin-bottom: 2rem;
    padding: 2rem 4rem 0;

    @include responsive(phone) {
      margin-top: 4rem;
    }

    &__header {
      text-align: center;
      border-bottom: 1px solid $color-grey-light;
      width: 100%;
      padding-bottom: 2rem;

      .p__1 {
        font-size: 2.4rem;
      }
    }

    &__tickets {
      padding: 2rem;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 0 14rem;

    @include responsive(phone) {
      flex-direction: column;
      padding: 0 2rem;
      align-items: center;
    }

    &--left {
      width: 45%;
      margin-right: 8rem;

      .p__2 {
        & p {
          line-height: 1.7 !important;
        }
      }

      @include responsive(phone) {
        width: 100%;
        margin-right: 0;
      }
    }

    &--right {
      border-radius: 4.1rem;
      max-width: 40%;
      border: 1px solid #000;
      padding: 4rem 0;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.56);
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-start;

      @media screen and (max-width: 750px) {
        max-width: 100%;
        margin-right: 0;
      }

    }
  }

  &__host {

    @include responsive(phone) {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    h5 {
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      font-size: 1.6rem;
    }
  }

  &__totals {
    margin: 2rem 0;
  }

  &__modal {
    left: 5%;
    margin: 5rem 0 0 0;

    .modal-content {
      width: 90vw;
    }
  }

  &__floorplan {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;

    // @include responsive(phone-sm) {
    //  margin-left: 24rem;
    // }

    // @include responsive(phone) {
    //  padding: 4rem 8rem;
    // }

  }

  &__continue {

    @include responsive(phone) {
      margin-top: 2rem;
    }
  }

  &__payment-form {
    width: 100%;
  }
}
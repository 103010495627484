.palace {
    // padding-left: 8rem;
    overflow-x: scroll;

    // Center palace rows instead of using margin
    // .palace__row {
    //     transform: translateX(20%);
    // }


    @media (max-width: 600px) {
        padding-left: 51rem;
    }

    &__wrapper {
        padding: 0 2rem;
        margin-bottom: 4rem;
        margin-top: 4rem;
    }  

    &__dash {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
    }

    &__theater {
        padding-right: 4rem;


    }

    &__cart {
        display: flex;
        flex-direction: column;
        width: 20rem;

        &--icon {
            color: $color-primary;
            font-size: 2rem;
        }
    }

    &__checkout {
        overflow: auto;

        @media (max-width: 600px) {
            // padding-left: 51rem;
        }
    }
}
.sidebar {
  background-color: $color-primary;
  flex: 0 0 18%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  transition: width 0.3s;

  &__spacer {
    width: 17rem;
    height: 100vh;
    flex-shrink: 0;

    @include responsive(phone) {
      width: 13rem;
    }
    
    @include responsive(phone-sm) {
      width: 8rem;
    }

    &--sm {
      width: 5rem;
      background-color: $color-grey-light-4;
    }
  }
}

.sidenav {
  font-size: 1.4rem;
  list-style: none;
  margin-top: 3.5rem;
  color: white;

  &__container {
    background-color: $color-primary;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 100;
    transition: width 0.3s;

    &--closed {
      background-color: transparent;

    }

    &.open {
      width: 17rem;
    }

    &.closed {
      width: 5rem;
    }
  }

  &__logo {
    width: 5rem;
    height: 7rem;

    &--closed {
      width: 3rem;
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;

      &--closed {
        // background-color: white;
        padding: 2rem .5rem;
      }

      @include responsive(phone) {
        padding: 1rem;
      }
    }
  }

  &__toggle-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.6rem;
    margin-top: 4rem;

    &--open {
      color: $color-primary;
    }
  }

  &__item {
    position: relative;
    color: $color-grey-light-2;

    @include responsive(phone) {
      width: 100%;
    }

    @media only screen and (max-width: $bp-medium) {
      flex: 1;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;

      @media only screen and (max-width: $bp-medium) {
        margin-bottom: 0;
        width: 100%;
      }
    }

    &:active::before {
      background-color: var(--color-primary);
    }

    &--active {
      font-weight: 900;
      color: white;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: var(--color-primary);
      transform: scaleY(0);
      transition: transform 0.4s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
    }

    &:hover::before,
    &--active::before {
      transform: scaleY(1);
      width: 100%;
    }
  }

  &__link {
    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      padding: 1.5rem 3rem;
      z-index: 10;
      position: relative;
      display: flex;
      align-items: center;

      @media only screen and (max-width: $bp-medium) {
        justify-content: center;
      }

      @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        padding: 1.5rem 0.5rem;
      }
    }
  }

  &__icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 2rem;
    margin-top: -0.75rem;
    fill: currentColor;

    & svg {
      width: 2rem;
      height: 2rem;
    }

    @media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-bottom: 0.7rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.floorplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  &__section {
    // margin-right: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__wrapper {
      position: relative;
    }

    &__cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;

      &:hover {
        cursor: pointer;
        background-color: rgba($color-primary, 0.1);
        transition: .4s;
      }
    }

    &__back {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    &--mobile {
      transform: scale(0.8);
    }
  }

  &__rows {

    &__container {

    }
  }
  
  &__transform {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__wrapper {
      width: 100%;
      height: 100%;
      max-width: 100vw;
    }
  }
  &__container {
      width: 100%;
      position: relative;
      min-height: 30rem;
  }

  &__loading {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__zoom {

    &__tools {
      position: absolute;
      z-index: 2;
      right: 1rem;
      top: 1rem;
      display: flex;
      flex-direction: column;

      button {
        margin-bottom: 1rem;
      }
    }
  }
  
}
.react-transform-wrapper {
  width: 90% !important;
}

// html, body {
//     background-color: #222;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
  
  // blockquote,dd,div,dl,dt,form,h1,h2,h3,h4,h5,h6,li,ol,p,pre,td,th,ul {
  //     margin: 0;
  //     padding: 0
  // }
  
  // p {
  //     font-size: inherit;
  //     line-height: 1.6;
  //     margin-bottom: 1rem;
  //     text-rendering: optimizeLegibility
  // }
  
  // a, h1,h2,h3,h4,h5,h6 {
  //     font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
  //     font-weight: 400;
  //     font-style: normal;
  //     color: inherit;
  //     text-rendering: optimizeLegibility;
  //     margin-top: 0;
  //     margin-bottom: .5rem;
  //     line-height: 1.4;
  //     color: white;
  // }
  
  // h1 small,h2 small,h3 small,h4 small,h5 small,h6 small {
  //     color: #cacaca;
  //     line-height: 0
  // }
  
  // h1 {
  //     font-size: 1.5rem
  // }
  
  // h2 {
  //     font-size: 1.25rem
  // }
  
  // h3 {
  //     font-size: 1.1875rem
  // }
  
  // h4 {
  //     font-size: 1.125rem
  // }
  
  // h5 {
  //     font-size: 1.0625rem
  // }
  
  // h6 {
  //     font-size: 1rem
  // }
  
  // a:hover, a:hover > * {
  //   color: grey;
  // }
  
  .categories--menu-container {
      position: relative;
      // height: 100%;
      // height: calc(100vh - 150px);
      width: 100%;
  }
  
  .categories {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    
    display: flex;
  }
  
  .category {
      position: relative;
  }
  
  ul {
    list-style: none;
  }
  
  .menu>li>a {
    display: block;
      padding: .7rem 1rem;
      padding-top: 0.7rem;
      padding-right: 1rem;
      padding-bottom: 0.7rem;
      padding-left: 1rem;
      line-height: 1;
  }
  
  
  /*content*/
  
  .category--content {
      position: absolute;
      top: 50px;
      white-space: nowrap;
      right: 0;
      transform: translateX(100%);
  }
  
  .category--content h2 {
      text-align: left;
      font-size: 44px;
      transform: translateX(-50px);
      text-transform: uppercase;
      font-weight: bold;
  }
  
  .category--content .project-list {
    padding: 0 0 0 30px;
  }
  
  .category--content,
  .category--content .project-list,
  .category--content .project-list .category {
      text-align: left;
  }
  
  .category--name {
    position: absolute;
    white-space: nowrap;
    width: calc(100% - 5px);
    transition: all 0.5s ease;
    padding-top: 5px;
    margin-top: 15px;
    margin-right: 5px;
    border-top: 1px solid #666;
    color: #666;
  }
  
  .category--name h6 {
    font-size: 16px;
    color: inherit;
    text-transform: capitalize;
  }
  
  .focused .category--name {
      opacity: 0;
  }
  
  .projectlist--client {
    font-size: 18px;
    margin-bottom: 0px;
    -webkit-text-stroke: 0;
    text-transform: capitalize;
    font-weight: bold;
  }
  .projectlist--byline {
    font-size: 12px;
    font-family: 'Nothing You Could Do', sans-serif;
  }
  
  /*last item content*/
  
  .isLast .category--content {
      right: initial;
      left: 0;
      transform: translateX(-100%);
  }
  
  .isLast .category--content h2 {
    transform: translateX(50px);
    text-align: right;
  }
  
  .isLast .category--content .project-list {
    padding: 0 30px 0 0;
  }
  
  .isLast .category--content,
  .isLast .category--content .project-list,
  .isLast .category--content .project-list .category {
    text-align: right;
  }
  
  
  /*inactive states*/
  
  .category {
      transition: transform 500ms ease;
      position: relative;
      z-index: 1;
  }
  
  .category--content {
      text-align: right;
      z-index: -1;
      visibility: hidden;
      pointer-events: none;
  }
  
  .category--content .project-list .category {
      opacity: 0;
      transition: all 300ms ease;
  }
  
  .category--image-container {
      background: #222;
      transition: all 500ms ease;
  }
  
  
  /*active states*/
  
  .category.isActive {
      z-index: 99;
  }
  
  .isActive .category--content {
      z-index: 99;
      visibility: visible;
      pointer-events: all;
  }
  
  .isActive .category--content .project-list .category {
      opacity: 1;
  }
  
  
  /*image states*/
  
  .category--image:hover, .category--image:focus, .focused .category--image:hover, .focused .category--image:focus {
      opacity: 1;
      cursor: pointer;
  }
  
  .category--image {
      opacity: 0.7;
      transition: opacity 500ms ease, width 500ms ease 100ms;
  }
  
  .focused .category--image {
      opacity: 0.25;
  }
  
  .focused .isActive .category--image {
      opacity: 0.75;
  }
  
  .category--closeButton {
      opacity: 0;
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: -5rem;
      transition: opacity 200ms ease;
  }
  
  .focused .isActive .category--closeButton {
    opacity: 1;
    z-index: 99;
  }
  
  .category--closeButton a {
    margin: -15px;
    padding: 15px;
  }
  
  /*hover state*/
  .category--image {
    /*transition: width 500ms ease;*/
  }
  .categories--menu-container:not(.focused) .category:hover .category--name {
    border-color: #eee;
    color: #eee;
  }
  
  /*Nav*/
  .right-arrow {
      position: fixed;
      bottom: 50px;
      right: 50px;
      display: inline-block;
  }
  
  .left-arrow {
      position: fixed;
      bottom: 50px;
      left: 50px;
      display: inline-block;
  }
  
  .left-arrow a, .right-arrow a {
    background-color: #222;
    z-index: 9;
    position: relative;
    padding: 5px;
    font-size: 12px;
  }
  .right-arrow img, .left-arrow img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 0;
    width: 140px;
    max-width: none;
  }
  
  .left-arrow img {
    transform: translate(-50%, -50%) scaleX(-1);
  }

// input {
//     border: none;
//     border-bottom: 1px solid $ch-color-grey-dark;
//     font-family: $default-font-family;
//     margin: 0 0 2rem 0;
//     font-size: 1.6rem;
// }

.input {
    width: 100%;
    border: none;
    border: 1px solid $color-grey-dark-2;
    background-color: inherit;
    font-family: inherit;
    margin: 0 0 2rem 0;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    border-radius: 1.2rem;

    // &:focus-visible {
    //     outline-width: 0;
    //     border: none;
    //     border-bottom: 1px solid $ch-color-gold;
    // }

    // &:focus {
    //     outline-width: 0;
    //     // border: none;
    //     border-bottom: 1px solid $ch-color-gold;
    // }

    &__container {
        position: relative;
    }

    &__price {
        padding-right: .5rem;
        font-size: 1.8rem;
        position: absolute;
        left: 1rem;
        bottom: 2.5rem;
    }

    &__label {
        font-family: $default-font-family;
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: .5rem;
        position: relative;
    }

    &__required {
        color: red;
        font-size: 1.4rem;
        position: absolute;
        right: -.8rem;
        top: -.5rem;
    }

    &__select {
        width: 100%;
        border: none;
        border: 1px solid $color-grey-dark-2;
        background-color: inherit;
        font-family: inherit;
        margin: 0 0 2rem 0;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        border-radius: 1.2rem;
    }

    &__textarea {
        width: 100%;
        height: 100px;
    }

    &__checkbox {
        // Basic checkbox styling
        width: 2rem;
        height: 2rem;
        border: 2px solid black;
        border-radius: 4px;
        appearance: none; // Remove default checkbox styling
        cursor: pointer;

        // When the checkbox is checked
        &:checked {
            background-color: $color-primary; // Fill with your primary color
            border-color: $color-primary;

            // Add a checkmark or any other custom indicator (optional)
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.6L5 9.6L13 1.6' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px 12px; // Adjust size of the checkmark if needed
        }

        // Optional: Add hover and focus states
        &:hover,
        &:focus {
            border-color: $color-primary; // Change border color on hover/focus
        }
    }

    &__radio {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    
        // Style for the actual radio input
        input[type="radio"] {
          appearance: none;
          width: 2rem;
          height: 2rem;
          border: 2px solid $color-primary;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s ease, border-color 0.3s ease;
    
          // Custom checked style with a dot in the middle
          &:checked {
            border-color: $color-primary;
            background-color: white; // Ensure background is white
            
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 1rem;
              height: 1rem;
              background-color: $color-primary; // Use the primary color for the dot
              border-radius: 50%;
            }
          }
    
          // Hover effect
          &:hover,
          &:focus {
            border-color: lighten($color-primary, 10%);
          }
        }
      }
}

.InputElement {
    font-size: 1.4rem !important;
}

#card-element {
    background-color: $ch-color-grey-light !important;
    font-family: $ch-font-regular !important;
    padding: 1rem 1.5rem !important;
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid $ch-color-grey-dark !important;
}

.image-upload {
    display: flex;
    flex-direction: column;

    &__label {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    &__sublabel {
        font-size: 1.2rem;
        color: rgba(60, 60, 60);
        margin-bottom: 2rem;
    }

    &__input {
        display: none;
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        background-color: $color-primary;
        color: white;
        border-radius: 50%;
        // cursor: pointer;
        position: absolute;
        transition: top 0.3s;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            // transform: translateY(-50%) scale(1.03);
            cursor: pointer;
            transition: 0.2s;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15rem;
        height: 22rem;
        background-color: #e0e0e0;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        /* Ensures the image does not overflow the parent */
    }

    &__container--file &__body {
        top: 1rem;
        transform: translateY(0);
    }

    &__placeholder {
        content: "+";
        font-size: 2rem;


    }

    &__feedback {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: #000;
        margin-top: 2rem;
        width: 100%;
        /* Ensures the feedback takes the full width */
        text-align: center;
    }

    &__preview {
        max-width: 10rem;
        max-height: 8rem;
        margin-top: 0.5rem;
        border-radius: 8px;
    }

    // Smaller style for the replace photo option
    &--small &__body {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
    }

    &--small &__container {
        width: 8rem;
        height: 10rem;
    }

    &--small &__feedback {
        font-size: 0.9rem;
    }

    &--small &__preview {
        max-width: 5rem;
        max-height: 4rem;
    }
}



.date-range {

    * {
        font-family: $default-font-family;

        @include responsive(phone) {
            // font-size: 1.4rem !important;
        }
    }
}

  // DatePicker
  .DateInput {
    background-color: transparent;
    width: 13rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(phone) {
      width: 10rem;
    //   margin-bottom: 2rem !important;
    }
  }

  .DateInput_input {
    background-color: transparent !important;

    @include responsive(t-port) {
    //   font-size: 1.4rem;
    }

    @include responsive(phone) {
    //   font-size: 1rem;
    }
  }

  .DateRangePicker {
    margin-right: 2rem;
  }

  .DateRangePickerInput {
    display: flex !important;
    align-items: center;
  }

  .DateRangePickerInput_arrow_svg {

    @include responsive(phone) {
      height: 16px;    
      width: 16px;
    }
  }
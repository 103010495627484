.tooltip {

}

.__react_component_tooltip {
    background-color: rgba(black, 1) !important;

    &::after {
        border-top-color: rgba(black, 1) !important;
    }

    &::before {
        border-top-color: rgba(black, 1) !important;
    }
}
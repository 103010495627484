@keyframes animation {
  0% {
  }
  100% {
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes pop-out {
  0% {
    left: -21rem;
    transform: rotate(16deg);
  }
  100% {
    left: -32rem;
    transform: rotate(-4deg);

    @include responsive(phone) {
      left: -29rem;
      transform: rotate(-2deg);
    }
  }
}

@keyframes pop-in {
  0% {
    left: -32rem;
    transform: rotate(-4deg);

    @include responsive(phone) {
      left: -29rem;
      transform: rotate(-2deg);
    }
  }
  100% {
    left: -21rem;
    transform: rotate(16deg);
  }
}


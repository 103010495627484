.scan {


  &__result {
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--success {
      background-color: $color-success;
    }

    &--invalid {
      background-color: $color-error;

      &-icon {
        font-size: 6rem;
        color: white;
        font-weight: 900;
      }
    }

    &--hidden {
      background-color: white;
    }
  }
}
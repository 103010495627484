.willcall {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center ;
  padding: 0 4rem;
  
  &__background {
    background-color: #ecf1fd;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }

  h1 {
    font-family: "trap";
    font-size: 6rem;
    color: #2029BA;
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  &__downloads {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    &-item {
      margin: 0 2rem;
      border: 1px solid $color-grey-light;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;

      svg {
        font-size: 3rem;
        color: $color-grey-dark;
        margin-right: 1rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }


  &__footer {
    // margin-top: 24rem;
  }
}
/* Reset CSS for cross-browser compatibility */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /* allows other elements to inherit box-sizing specified in body */
  box-sizing: inherit;
}

html {
  /* this makes it a lot easier to calcute rem. 62.5 is 10/16 so our defualt fontsize is relative to user settings. defines what 1 rem is */
  scroll-behavior: smooth;
  font-size: 62.5%; //1rem = 10px


// Media query order should always be descending. This is the only way the browser can properly apply the styling to each screen size respectively.

//The reason being, that because both apply (both conditions being true--the screen is smaller thna 1200px AND 900px), the most recent code, will win the tie breaker, and be applied.

  @include responsive(l-desk) {
    font-size: 75%; //1rem = 12px 12/16 = 75%
  }

  @include responsive(t-land) { // width < 1200?
    font-size: 56.25%; //1rem = 9px 9/16 = 56.25%
  }

  @include responsive(t-port) { // width < 900?
    font-size: 45%; //1rem = 8px 8/16 = 50%
  }
  // background-color: #222;
  width: 100%;
  height: 100%;
}



body {
    /* makes it so that padding and width don't add to total size of element. i.e. height and width will now extend to define other parts of the box model */
    box-sizing: border-box;
    // background-color: #222;
}

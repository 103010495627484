//  Ticket dimensions: 1.75 * 5.5
// Paper dimesions 8.5 x 11
// ?? Ticket width: 5.5, img: 4, stub: 1.5
:root {
  --ticket-container-width: 200rem;
  --ticket-container-height: calc(var(--ticket-container-width) * .77);
  --ticket-width: calc(var(--ticket-container-width)/2);
  --ticket-height: calc(var(--ticket-container-height)/5);
}

.tickets {
  position: relative;



  &__container {
    width: var(--ticket-container-width);
    height: var(--ticket-container-height);
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-start;
    flex-direction: column;
  }



  &__null {
    height: 64.7rem;
    width: 200rem;
  }


  &__img {
    height: var(--ticket-height);
    width: var(--ticket-width);
  }

  &__date {
    position: absolute;
    font-family: 'Cinzel', serif;

    &--body {
      font-size: 1.4rem;
      bottom: 3.5rem;
      left: 4rem;
    }

    &--stub {
      font-size: 1.4rem;
      top: 7rem;
      right: 10.5rem;
    }
  }

  &__seat {
    position: absolute;
    font-family: 'Cinzel', serif;

    &--body {
      font-size: 2rem;
      bottom: 3rem;
      right: 37rem;
    }

    &--stub {
      font-size: 2rem;
      top: 13.5rem;
      right: 8.5rem;
    }
  }

  &__barcode {
    mix-blend-mode: multiply;
    position: absolute;
    // transform: rotate(-90deg);
    width: 12rem;
    right: 4rem;
    bottom: 0rem;
    // width: 6.426rem;
    width: 27%;
  }

  &__text {
    font-family: 'Cinzel', serif;
    font-size: 1.8rem;
    position: absolute;
    // transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;

    &--black {
      color: black;
      bottom: -.6rem;
      right: 20.3rem;
    }

    &--white {
      color: white;
      top: 7.8rem;
      left: -4rem;
    }
  }

  &__dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 1rem;

    &--black {
      background-color: #000000;
    }

    &--white {
      background-color: white;
    }
  }
}

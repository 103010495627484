.collapsible {
    // display: inline-block;
    width: 100%;
    margin-bottom: 3px;
  
    &:hover {
  
  
    }
    
    &__btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: black;
      color: white;
      width: 100%;
      max-width: 100%;
      padding: 1rem;
      border: none;
      font-size: 1.8rem;
      
      &:hover {
        cursor: pointer;
  
        & * {
          font-weight: 700;
        }
      }
  
      &:active {
      }
      
      &:focus {
        border-radius: 0;
        outline: none;
      }
      
      &--title {
        margin-right: 2rem;
        font-size: inherit;
      }
      
      &--icon {
  
      }

      &--sm {
        // border: 1px solid $color-primary;
        font-size: 1.4rem !important;
        color: white;
        padding: .5rem;
        // background-color: $color-primary-light;

      }
    }
    
    &__content {
      // animation: dropdown .4s ease-out forwards;
      width: 100%;
    }

    &__icon {
      color: white;
      font-size: 2rem;

      &--sm {
        color: white;
      }
    }
  }
  
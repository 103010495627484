.card {
    // FUNCTIONALITY
    perspective: 150rem;
    //for firefox
    -moz-perspective: 150rem;
    position: relative;
    z-index: 100;
    height: 100%;
    width: 100%;
  
    &__side {
        height: 52rem;
        transition: all .8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 1.5rem 4rem rgba(0,0,0, .15);
  
        &--front {
            background-color: white;
        }
  
        &--back {
            transform: rotateY(180deg);
            background-color: $color-primary;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-1 {
              background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
            }
            &-2 {
              background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
            }
  
            &-3 {
              background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
            }
        }
    }
  
  
    &:hover &__side--front {
        transform: rotateY(-180deg);
    }
  
    &:hover &__side--back {
        transform: rotateY(0);
    }
  
    //FRONT SIDE STYLING
    &__picture {
      background-size: cover;
      height: 23rem;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      // background-size: cover;
      // background-blend-mode: screen;
  
    }
  
    &__heading {
      font-size: 2.8rem;
      font-weight: 300;
      text-transform: uppercase;
      color: white;
      text-align: right;
      position: absolute;
      top: 12rem;
      right: 3rem;
      width: 45%;
    }
  
    &__heading-span {
      padding: 1rem 1.5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      padding: 1rem 1.5rem;
  
    }
  
    &__about {
      padding: 2rem;
  
      &--name {
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
      }
  
      &--desc {
        font-size: 1.4rem !important;;
        color: black !important;
      }
    }
    
  
  
    //BACK-SIDE STYLING
    &__details {
      padding: 2rem;
      color: white;
  
      &--stack {
        font-size: 3rem;
        text-align: center;
        letter-spacing: .5rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        word-wrap: break-word;
      }
  
      &--logo {
        height: 5rem;
        width: 5rem;
        transform: translateY(-25%);
        position: absolute;
        left: 0;
        
      }
  
      ul {
        list-style: none;
        width: 80%;
        margin: 0 auto;
  
        li {
          text-align: center;
          font-size: 1.5rem;
          padding: 1.5rem;
          position: relative;
          text-transform: uppercase;
  
          &:not(:last-child) {
            border-bottom: 1px solid rgb(200,200,200);
          }
        }
      }
  
  
    }

    &__link {

    }
  
  }
  
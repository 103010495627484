.dropdown {
    height: 100%;
  
    &__btn {
      border: none;
      background-color: black;
      color: white;
      font-family: $default-font-family;
      padding: .5rem 4rem;
      border-radius: 10rem;
      font-size: 1.6rem;
      font-weight: 700;
      display: inline-block;
  
      & * {
        transition: color 0.2s;
      }
  
      &--active {
        background-color: black;
      }
  
      &:hover {
        // background-color: $midblue;
        cursor: pointer;
        transition: 0.2s;
        background-color: $color-primary;
  
        & * {
        //   color: $yellow !important;
          transition: color 0.2s;
        }
      }
  
      &:focus {
        // background-color: $midblue;
      }
  
      &:active {
        // background-color: $midblue;
      }
    }
  
    &__content {
      position: absolute;
      z-index: 99;
      background-color: white;
      padding: 2rem;
      width: 20rem;
      background-color: $color-grey-light;
      top: 6rem;
      // overflow: scroll;
  
      &--right {
        right: 0;
      }
    }
  
    &__arrow {
    }
}
.show-card {
    width: 30.5rem;
    height: 34rem;
    border-radius: 2rem;
    overflow-y: auto;
    position: relative;
    border: $ch-color-grey-light solid 1px;
    box-shadow: 0px 3px 3px 0px rgba(39, 45, 59, 0.20);
    margin: 0 4rem 4rem 0;

    @include responsive(phone) {
        // width: 100%;
        margin: 0 0 4rem 0;
    }
    
    &__header {
        width: 100%;
        height: 20rem;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: relative;
        z-index: 2;

        &--title {
            font-family: $default-font-family;
            font-size: 1.4rem;
            font-weight: 700;
            letter-spacing: 0.032rem;
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            
            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
                transition: .2s;
            }
        }
    }

    &__subtext {
        color: $color-grey-dark;
        font-size: 1.2rem;
        font-weight: 400;
        font-family: $default-font-family;
        line-height: 1.2; 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        &--ko {
            color: $color-grey-light;
        }
      
        &--red {
          color: red;
          font-size: 1.2rem;
          font-weight: 400;
          font-family: $default-font-family;
        }
    }

    &__footer {
        max-height: 14rem;
    }

    &__add {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &--btn {

            font-size: 3rem;
            color: white;
            background-color: black;
            border-radius: 50%;
            width: 5.4rem;
            height: 5.4rem;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px black solid;
        }

        &--text {
            max-width: 12rem;
        }

        
        &:hover {
            transform: translateY(-.3rem);
            box-shadow: 0 1rem 1.5rem rgba(0,0,0,.2);
            color: $color-primary;
            transition: .2s;
            cursor: pointer;
        }

        &:active,
        &:focus {
          outline: none;
          transform: translateY(-1px);
          box-shadow: 0 .5rem .7rem rgba(0,0,0,.2);
        }
    }

    &__delete {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    &__description {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 1rem;
        height: 20rem;
        overflow: auto;
        background-color: rgba(black, .7);
        opacity: 0;

        &:hover {
            opacity: 1;
            transition: .4s;
        }
    }
    
    &__info {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        &--left {
        }

    }

    &__datetime {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;
    }

    &__date {
        display: flex;
        align-items: center;
        font: $default-font-family;
        flex: 0 1 auto;
        background-color: #E1F2FF;
        padding: .3rem;
        border-radius: 0rem 0rem 0.3rem 0.3rem;
        
        &--container {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: 0;
            top: -2rem;
            z-index: 4;
        }

        &--header {
            border-radius: 0.3rem 0.3rem 0rem 0rem;
            // background: linear-gradient(0deg, #CDEAFF 0%, #CDEAFF 100%), linear-gradient(0deg, #E1F2FF 0%, #E1F2FF 100%), #E1F2FF;
            background-color: $color-blue-light;
            width: 100%;
            height: 1.3rem;
        }
        &--day {
            font-size: 2.2rem;
        }

        &--text {
            margin-left: .5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        &--weekday {
            color: $color-grey-dark;
            margin-top: .2rem;
        }

        &--month {
            margin-top: -.6rem;
        }
    }

    &__time {
        margin-top: .5rem;
        font-size: 1.2rem;
        display: inline-block;
        color: $color-grey-dark;
    }

    &__manage {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 1rem;
        padding: 0 1rem;
    
    }

    &__status {
        border-radius: 1.7rem;
        padding: .2rem 1.5rem;
        font-size: 1.4rem;
        // font: $default-font-family;

        &--active {
            color: $color-green-dark;
            background-color: $color-green-light;
        }

        &--draft {
            color: white;
            background-color: $color-grey-dark;
        }

        &--past {
            color: white;
            background-color: rgba(0, 0, 0, 0.73);;
        }
    }

    &__shows {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

}
.maintenance {
  background-color: $color-primary;
  height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  &__logo {
    width: 40rem;
    margin:6rem 0 25rem 0;
  }

  &__banner {
    color: $color-primary;
    background-color: white;
    font-family: Trap;
    font-size: 2.7rem;
    font-weight: 700;
    height: 4rem;
    width: 120vw;

    &--text {
      transform: rotate(-10.867deg);
      overflow: hidden;
    }

    &--plain {
      transform: rotate(4.373deg);
      opacity: .8;
    }

    & span {
      margin-right: 6rem;
    }
  }
}
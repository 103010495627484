.u {

    // ANIMATIONS
    &-fade-up-out {
        animation: fadeUpOut 0.3s ease-out forwards !important;
    }

    &-move-up-far {
        animation: moveUpFar 0.3s ease-out forwards !important;
    }

    &-move-up-close {
        animation: moveUpClose 1.5s ease-out forwards !important;
    }

    &-move-up {
        transform: translateY(-15rem);
    }

    &-hidden {
        display: none;
    }

    &-fadeout {
        animation: fadeOut 1s ease-out forwards;
    }

    &-align-center {
        text-align: center;
    }

    // MARGIN
    &-mgn {
        &-auto {
            margin: auto;
        }

        &-md {
            margin: 4rem !important;
        }

        &-btm-sm {
            margin-bottom: 1rem !important;
        }

        &-btm-md {
            margin-bottom: 4rem !important;
        }

        &-btm-lg {
            margin-bottom: 8rem !important;
        }

        &-top-sm {
            margin-top: 1rem !important;
        }

        &-top-md {
            margin-top: 4rem !important;
        }

        &-top-lg {
            margin-top: 8rem !important;
        }

        &-left-sm {
            margin-left: 1rem !important;
        }

        &-left-md {
            margin-left: 4rem !important;
        }

        &-left-lg {
            margin-left: 8rem !important;
        }

        &-left-xl {
            margin-left: 12rem !important;
        }

        &-right-sm {
            margin-right: 1rem !important;
        }

        &-right-md {
            margin-right: 4rem !important;
        }

        &-right-lg {
            margin-right: 8rem;
        }

        &-none {
            margin: 0 !important;
        }

        &-vert {
            &-sm {
                margin: 1rem 0;
            }

            &-md {
                margin: 4rem 0;
            }

            &-lg {
                margin: 8rem 0;
            }
        }

        &-neg {

            &-btm {
                margin-bottom: -1rem;
            }
        }
    }

    // PADDING
    &-pad {
        &-sm {
            padding: 1rem;
        }

        &-md {
            padding: 4rem;
        }

        &-lg {
            padding: 8rem;

            // @include responsive()
        }

        &-btm-sm {
            padding-bottom: 2rem !important;
        }

        &-btm-md {
            padding-bottom: 4rem !important;
        }

        &-btm-lg {
            padding-bottom: 8rem !important;
        }

        &-top-sm {
            padding-top: 2rem !important;
        }

        &-top-md {
            padding-top: 4rem !important;
        }

        &-top-lg {
            padding-top: 8rem !important;
        }

        &-left-sm {
            padding-left: 2rem !important;
        }

        &-left-md {
            padding-left: 4rem !important;
        }

        &-left-lg {
            padding-left: 8rem !important;
        }

        &-right-sm {
            padding-right: 2rem !important;
        }

        &-right-md {
            padding-right: 4rem !important;
        }

        &-right-lg {
            padding-right: 8rem;
        }

        &-none {
            padding: 0 !important;
        }

        &-vert {
            &-sm {
                padding: 1rem 0;
            }

            &-md {
                padding: 2rem 0;
            }

            &-lg {
                padding: 4rem 0;
            }
        }


        &-hor {
            &-sm {
                padding: 0 2rem;
            }

            &-md {
                padding: 0 4rem;
            }

            &-lg {
                padding: 0 8rem;
            }
        }
    }

    // BORDER
    &-border-primary-sm {
        border: solid $color-primary 2px !important;
    }

    &-inactive {
        display: none !important;
        visibility: hidden;
    }

    &-visibility {

        &-hidden {
            visibility: hidden;
        }
    }

    &-glow {
        box-shadow: 1rem 2rem 2rem rgba(gold, 0.6);
    }

    &-opaque {
        opacity: 0.4;
    }

    // FLEX
    &-flex {
        display: flex;

        &-column {
            flex-direction: column;
        }

        &-row {
            flex-direction: row !important;
        }

        &-wrap {
            flex-wrap: wrap;
        }

        &-all-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-justify {
            &-start {
                justify-content: flex-start;
            }

            &-end {
                justify-content: flex-end;

                &-self {
                    justify-self: flex-end;
                }
            }

            &-between {
                justify-content: space-between;
            }

            &-evenly {
                justify-content: space-evenly;
            }

            &-around {
                justify-content: space-around;
            }

            &-center {
                justify-content: center;

                &-self {
                    justify-self: center;
                }
            }
        }

        &-align {
            &-start {
                align-items: flex-start;

                &-self {
                    align-self: flex-start;
                }
            }

            &-end {
                align-items: flex-end;

                &-self {
                    align-self: flex-end;
                }
            }

            &-center {
                align-items: center;
            }
        }


        &-self {
            &-justify {
                &-end {
                    justify-self: flex-end;
                }
            }

            &-align {
                &-end {
                    align-self: flex-end;
                }
            }
        }

        &-grow {
            flex-grow: 1;
        }
    }

    // POSITION
    &-top-right {
        position: absolute;
        z-index: 100;
        right: 1rem;
        top: 1rem;
    }

    &-pos-relative {
        position: relative;
    }

    &-high-z {
        z-index: 10000;
    }

    &-full-screen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }

    // COLOR
    &-white {
        color: white !important;
    }

    &-white-back {
        background-color: white !important;
    }


    &-black {
        color: black !important;
    }

    &-secondary {
        color: $color-secondary  !important;
    }

    &-secondary-hov {
        &:hover {
            color: $color-secondary !important;
            cursor: pointer;
        }
    }

    &-border-default {
        border: 1px solid $color-primary;
    }

    // FONT
    &-font-bold {
        font-weight: 900 !important;
    }

    &-font-semibold {
        font-weight: 600 !important;
    }

    // LAYOUT
    &-full-vh {
        height: 100vh;
    }

    // LINKS

    &-link {
        &--simple {
            color: $color-primary;
            &:hover {
                color: $color-primary  !important;
                text-decoration: underline;
            }
        }
    }

    // HOVER
    &-hover {

        &-pointer {
            &:hover {
                cursor: pointer;
            }
        }
    }

    // SIZING
    &-full-width {
        width: 100% !important;
    }

    &-layout {

        &-page {
            width: 70%;
            margin: auto;
            overflow: auto;

            section {

                p {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    // TYPOGRAPHY
    &-align-center {
        text-align: center;
    }

    &-strike {
        text-decoration: line-through;
    }

    &-link {
        color: #1A5C5C;
    }

    &-overflow {

        &-auto {
            overflow: auto;
        }
    }
}

$dark_background: #E5E5E5;

.landing {
    font-family: "trap";
    max-width: 100vw;
    overflow: hidden;
    &__events {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &>* {
            font-family: "trap";
        }
        &__slider {
                width: 100vw;
                height: fit-content;
                max-height: 60vh;
                // background-color: $dark_background;

                @include responsive(phone) {
                    display: none;
                }
            }
        &>a {
            text-decoration: underline;
            @media (max-width: 480px) {
                width: 100%;
                }
            h2 {
                margin: 4rem 0 0;
                padding-left: 4rem;
                font-weight: 900;
                font-family: "trap";
                @media (max-width: 480px) {
                        text-align: center;
                    }
            }
        }

        &__heading {
            display: none;
            @include responsive(phone) {
                display: block;
                text-align: center;
                font-size: 3.2rem;
                font-weight: 400;
                border-bottom: 1px solid black;
                margin: 4rem 0 2rem 4rem;
                color: black;
                letter-spacing: 1px;
            }
        }
        &__wrap {
            padding: 2.5rem 4rem;
            width: 100%;
            display: grid;
            box-sizing: border-box;
            grid-template-columns: repeat(4, minmax(300px, 1fr));
            column-gap: 2.5em;
            row-gap: 2.5em;
            align-items: stretch;
            @media (max-width: 1200px) {
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                }
            @media (max-width: 480px) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
            
            &>div {
                min-height: 450px;
                margin-bottom: 1rem;
            }
            // &>* {
            //     // flex: 1 35rem;
            //     margin: 0 2rem 2rem 0;
            // }
        }
    }

    &__hero {
        height: 35rem;
        background-position: 10% 75%;
        background-size: cover;
        position: relative;

        &--overlay {
            background-color: rgba($color-primary-dark, .7);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

        }
    }

    &__heading {
        position: absolute;
        left: 10%;
        top: 20%;
        z-index: 10;
    }


    &__featured {
        padding: 0 4rem;
        margin-top: 2rem;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;

        &>* {
            // flex: 1 35rem;
            margin: 0 2rem 2rem 0;
        }
    }

    &__about {
        padding: 20rem 4rem;
        // background-color: $color-primary;
        background-size: cover;
        border: 3px solid $color-secondary;
        // background-size: cover;
        transform: skewY(-7deg);
        margin: 17rem 0;

        &>* {
            transform: skewY(7deg);
            color: white;
        }
    }

    &__carousel {

        &-item {
            height: 60vh;
            display: flex;
            // padding: 4rem 0;
            position: relative;
            justify-content: center;
            align-items: center;

            &__bg {
                filter: blur(40px);
                opacity: .4;
                width: 100%;
                height: 100%;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }

            &__poster {
                // max-width: 40rem;
                // max-height: 40rem;
                border-radius: 1rem;
                height: 38rem;
                width: 33rem;
                background-size: cover;
                background-position: center;
                box-shadow: 0px 8px 11px rgba(0,0,0,.25);
                margin-right: 8rem;
            }

            &__content {
                position: relative;
                z-index: 1;
                max-width: 40%;
            }

            h3 {
                font-size: 5.6rem;
                font-family: trap;
                // color: $color-primary;
                font-weight: 900;
                line-height: 1;
                margin-bottom: 1.4rem;
            }

            p {
                margin-bottom: 1.4rem;
                font-family: $default-font-family;

                svg {
                    width: 1.2rem;
                    margin-right: 1rem;
                    fill: #3e4642;
                }
            }
            button {
                margin-top: 2rem;
                color: #F1F1F1;
                font-weight: 500;
                padding: 1.5rem 3rem;
                box-shadow: 0px 8px 11px rgba(0,0,0,.25);
            }
        }
    }

}
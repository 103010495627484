.button {
  background-color: $color-primary;
  color: white;
  border: none;
  padding: 1rem 4rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }

  &--alt {
    color: white;
    border: none;
    padding: 1rem 4rem;
    border-radius: 10rem;
    font-size: 1.6rem;
    font-weight: 700;
    background: linear-gradient(270deg, #FF978E 105.51%, #FFB784 -51.89%);
  }

  
  &:active {
    // transform: translateY(2px);
    opacity: .8;
    transition: .2s;
  }
  
  &:hover {
    cursor: pointer;
    // transform: translateY(-2px);
    opacity: .8;
    transition: .2s;
  }

  // GIVES THE BUTTON A "CLICK" EFFECT
  // &:hover {
  //   transform: translateY(-.3rem);
  //   box-shadow: 0 1rem 1.5rem rgba(0,0,0,.2);
  //   color: $color-primary;
  //   transition: .2s;
  //   cursor: pointer;
  // }

  // &:active,
  // &:focus {
  //   outline: none;
  //   transform: translateY(-1px);
  //   box-shadow: 0 .5rem .7rem rgba(0,0,0,.2);
  // }
  
  &--sm {
    padding: .5rem 1rem;
    font-size: 1.2rem;
  }
  

  &--ko {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;
    font-weight: 500;
  }

  &--disabled {
    background: $ch-color-grey-light !important;
    color: black !important;

    &:hover {
      transform: translateY(0px);
      transition: .2s;
      cursor: default;
    }
  }

  &--action {
    background: var(--slicktix-gradiant-1, linear-gradient(90deg, #9BC9FF 0%, #D59EEF 55%, #FF7FE3 100%));
  }

  &--inactive {
    background-color: $color-slate;

    &:hover {
      cursor: default;
      transform: translateY(0) !important;
    }
  }

  &__add {
    font-size: 2rem;
  }

  
  &__tab {
    background-color: $color-primary;
    border: none;
    padding: 1rem;
    border-radius: 30px;
    font-size: 1.6rem;
    font-weight: 700;
    color: white;
  
    &:active {
      transform: translateY(2px);
      transition: .2s;
    }
  
    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
      transition: .2s;
    }
  
    &--ko {
      background-color: $color-secondary;
      color: white;
    }

    &--active {
      background-color: $color-primary-dark;
    }
  }

  &--pad {
    padding: 2rem 4rem;
  }

  &--brand {
    

    &--lg {
      padding: 1rem 6rem;
      font-size: 2.4rem;
      font-family: Impact
    }

    &--primary {
      background: linear-gradient(to top right, 
      #9972fb, #cb72fb);
    }
    
    &--secondary {
      background: linear-gradient(to top right, #FEC180, #FF8993);
    }
  }

}

.manage-btn {

  &--edit {
    background-color: $color-blue-light;
    color: $color-blue-dark;
    font-family: $default-font-family;
    font-size: 1.2rem;
    padding: .5rem .75rem;
    border: none;
    border-radius: .6rem;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
      transition: .4s;
    }
  }

  &--delete {
    background-color: rgba(#FF0B0B, .7);
    color: white;
    font-family: $default-font-family;
    font-size: 1.2rem;
    padding: .5rem .75rem;
    border: none;
    border-radius: .6rem;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
      transition: .4s;
    }
  }

}
.signup-complete {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__heading {
    font-size: 8rem;
    color: $color-primary;
    font-family: $alt-font-family;
    text-align: center;
    margin-top: 2rem;
  }

  &__text {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #333;
  }

  &__particles-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none; // Disable interaction with particles
  }

  &__particle {
    position: absolute;
    width: 50px; // Size of the particle
    height: 20px;
    // background-color: #ffcc00;
    border-radius: 5px;
    opacity: 0.9;

    &--type1 {
      background-color: #ffcc00; // Yellow tickets
      color: #ffcc00;
      fill: #ff5733;
    }

    &--type2 {
      background-color: #ff5733; // Red tickets
      fill: #ff5733;
      color: #ff5733;
    }
  }
}
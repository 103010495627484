.navbar {
    font-family: "trap";
    width: 100%;
    padding: 2rem 4rem;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // @media (max-width: 1153px) {
    //     padding: 2rem 0rem;
    // }

    @include responsive(phone) {
        padding: 1.8rem 2rem;
    }

    &>div {
        width: 50%;
        padding-left: 2rem;
        
    }
    &__logo {
        // height: 5rem;
        width: 20rem;
        margin-right: 4rem;
        margin-left: 2rem;
        @include responsive(phone) {
            margin-right: -4rem !important;
            margin: 0;

            width: 15rem;
        }
        
        // border-radius: 24px;
    }

    &__buffer {
        margin-bottom: 7rem;
    }

    &__section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 2rem;

        @include responsive(phone) {
            padding-right: 0;
        }
        @media (max-width: 1153px) {
           width: auto;
        }
        &>* {
            margin: 0;
        }
        @include responsive(phone) {
            width: auto;
            margin-right: 0;
        }

        &--right {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        &>p {
            @media (max-width: 480px) {
                    display: none;
                }
        }
        &>.login__link {
            font-size: 2rem;
        }
        &>.dropdown {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &>.launch__btn, &>.dropdown .dropdown__btn {
            background: #9FFFBC;
            color: #000000;
            text-align: center;
            padding: 8px;
            border: 3px solid #000000;
            box-shadow: -5px 5px 1px #000000;
            width: 30%;
            border-radius: 33px;
            font-size: 1.5rem;
            font-weight: 600;
            font-family: "trap";
            @media (max-width: 780px) {
                width: 60%;
                padding: 7px 6px;
                }
        }
        &>img {
            width: 35px;
        }
    }

    &__auth {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 1rem;
        @include responsive(phone) {
            display: none;
        }

        button {

            &:hover {
                background-color: white;
                border: 1px solid $color-primary;
                transition: .3s;
                color: $color-primary;
            }
        }
    }

    &__link {
        display: contents;
    }

    &__menu {
        padding: 2rem;
        margin-left: 1rem;
        color: $color-primary;
        
        @include responsive(phone) {
            margin-right: 3rem;
            padding: 2rem 0;
        }

        &:hover {
            cursor: pointer;
            scale: 1.1;
            transition: .3s;
        }

        & img {
            width: 4rem;
        
        }
    }
}

.login {

    &__link {
        margin-right: 4rem;

        &:hover {
            cursor: pointer;
        }
    }
}
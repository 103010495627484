.profile {
  margin-left: 2rem;

  h1 {
    font-size: 3rem;
  }

  input {
    max-width: 70vw;
  }

  .input__container {
    display: flex;
    flex-direction: column;
  }
}